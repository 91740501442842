import { ReactNode } from "react"

export const CustomButton = ({text, onClick}: {text: string, onClick: () => void,}) => {
    return <button 
    className="border border-[#4995CF] text-[#4995CF] border-[1px] rounded-full h-[36px] w-[180px] align-center" 
    onClick={() => onClick()}>{text}</button>
}

export const CustomButtonNonSubmit = ({text, onClick}: {text: string, onClick: () => void,}) => {
    return <button 
    type="button"
    className="border border-[#4995CF] text-[#4995CF] border-[1px] rounded-full h-[36px] w-[180px] align-center" 
    onClick={() => onClick()}>{text}</button>
}

export const CustomButtonSmall = ({text, children, onClick}: {text: string, children?: ReactNode, onClick: () => void}) => {
    return <button 
    className="border border-[#4995CF] text-[#4995CF] border-[1px] rounded-full  h-[26px] px-2 align-center text-[12px]" 
    onClick={() => onClick()}>{children} {text}</button>
}