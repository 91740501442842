import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export const SearchableDropdown = (props: any) => {
    const { options, onChange, value, label } = props;

    return (
        <Autocomplete
            options={options.options}
            getOptionLabel={(option) => option}
            onChange={(event, selectedOption) => {
                const inputValue = (event.target as HTMLInputElement).value;
                onChange(selectedOption || inputValue);
            }}
            renderInput={(params) => (
                <TextField {...params} label={label} variant="outlined" onChange={(event) => {
                    // Update the value when typing
                    const inputValue = (event.target as HTMLInputElement).value;
                    onChange(inputValue);
                }} />
            )}
            isOptionEqualToValue={(option, value) => option === value}
            value={value}
            // disableClearable
            freeSolo={true}
            sx={{
                height: '56px', // Adjust the height here
                '& .MuiOutlinedInput-root': {
                    height: '56px', // Ensure input has the same height
                    '& fieldset': {
                        height: '56px', // Adjust fieldset height if needed
                    },
                },
            }}
        />
    );
};

// export const AutocompleteDropdown = (props: any) => {
//     const { options, onChange, value, label } = props;

//     return (
//         <Autocomplete
//             freeSolo
//             options={options.options}
//             value={value}
//             // onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
//             onChange={(event, selectedOption) => {
//                 onChange(selectedOption ? selectedOption : '');
//             }}
//             renderInput={(params) => <TextField {...params} label={label} />}
//             renderOption={(props, option) => (
//                 <li {...props} key={option}>
//                     {option}
//                 </li>
//             )}
//         />
//     );
// }