import { Navigate, useRoutes, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuth } from './auth/useAuth';
import { SignIn } from './auth/signin';
import { Signup } from './auth/signup';
import ProtectedRoute from './protectedRoute';
import { UserData } from './userData/userData';
import { Dashboard } from './Dashboard/dashboard';
import './App.css';
import { PublicView } from './PublicView/Publicview';
import PublicRoute from './publicRoute';
import { ForgotPassword } from './auth/forgotPassword';

export const App = () => {
  const { user, loading } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && (location.pathname === '/signin' || location.pathname.includes('/signup'))) {
      navigate('/');
    }
  }, [user, location, navigate]);

  const routes = useRoutes([
    {
      path: '/signin',
      element: <SignIn />,
    },
    {
      path: '/signup',
      element: <Signup />
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />
    },
    {
      path: '/signup/:email',
      element: <Signup />
    },
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      )
    },
    {
      path: '/profile',
      element: (
        <ProtectedRoute >
          <UserData />
        </ProtectedRoute>
      )
    },
    {
      path: '/permissions',
      element: (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      )
    },
    {
      path: '/ad-compliances/:aircraftId',
      element: (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      )
    },
    {
      path: '/mx-log/:aircraftId',
      element: (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      )
    },
    {
      path: '/pilots',
      element: (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      )
    },
    {
      path: '/pilot-logbook/:email',
      element: (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      )
    },
    {
      path: '/view-only/:aircraftId/*',
      element: (
        <PublicRoute>
          <PublicView />
        </PublicRoute>
      )
    },
    // Other routes
  ]);

  if (loading) {
    return <div>Loading Airlogbooks...</div>;
  }

  return routes;
};

export default App;
