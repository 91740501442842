import { Card, Modal } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { CustomButtonFill } from "./buttonfill";
import { CustomButton } from "./button";

export const DeleteConfirmModal = ({ title, onDelete, onClose }: { title: string, onDelete: () => void, onClose: () => void }) => {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 3,
    };

    return <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Card className='' sx={style}>
            <div className='flex  flex-row justify-between'>
                <h1 className="text-3xl mb-5">{title}</h1>
                <CloseIcon className='hover:cursor-pointer' onClick={() => onClose()}></CloseIcon>
            </div>
            <h3>
                This can not be undone. You will lose all data! Are you sure?
            </h3>

            <div className="flex mt-5 gap-3">
                <CustomButtonFill text='Yes' onClick={async () => {
                    onDelete();
                    onClose()
                }} />
                <CustomButton text="No" onClick={() => onClose()} />
            </div>

        </Card>
    </Modal>
}