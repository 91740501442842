import { useEffect, useState } from "react";
import { getDownloadURL, getStorage, ref, uploadBytes, uploadBytesResumable } from "firebase/storage";

export const FileUploadWidget = (props: any) => {
    const [uploading, setUploading] = useState(false);
    const [imageUrl, setImageUrl] = useState(props.value || null); // Use initial value if provided
    const [error, setError] = useState(null);
    const [isDragOver, setIsDragOver] = useState(false);
    const storage = getStorage();

    useEffect(() => {
        const fetchImageUrl = async () => {
            try {
                const url = await getDownloadURL(ref(storage, imageUrl))
                setImageUrl(url);
            } catch (error) {
            }
        };

        if (imageUrl != null && imageUrl.startsWith("images")) {
            fetchImageUrl();
        }

    }, [imageUrl]);

    const handleFileUpload = async (event: any) => {
        const fs = getStorage();
        const file = event?.target?.files?.[0] || event?.dataTransfer?.files?.[0];
        if (!file) return;

        setUploading(true);
        console.log("uploading....")
        setError(null);

        try {
            const fileName = new Date().toISOString() + '___' + file.name;
            // Create a reference to the file in Firebase Storage
            const storageRef = ref(fs, `images/${fileName}`);

            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadBytes(storageRef, file).then((snapshot) => {
                console.log('Uploaded a blob or file!');

            });

            uploadTask.on('state_changed',
                (snapshot) => {
                },
                (error) => {
                    console.error("error upload!!!")
                    setUploading(false);
                },
                () => {
                    // Upload completed successfully, now we can get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        setImageUrl(downloadURL)
                        props.onChange(`images/${fileName}`);
                        setUploading(false);
                    });
                }
            );
        } catch (error) {
            console.error("Error uploading file:", error);
        } finally {
        }
    };

    const handleDragEnter = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragOver(true);
    };

    const handleDragLeave = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragOver(false);
    };

    const handleDragOver = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDrop = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragOver(false);
        handleFileUpload(event);
    };

    return (
        <div
            className={`flex flex-col items-center p-4 border-2 border-dashed rounded-md cursor-pointer ${isDragOver ? 'border-blue-500 bg-blue-50' : 'border-gray-300'}`}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            <div className="flex">
                {imageUrl && (
                    <div className="mr-5">
                        <img
                            src={imageUrl}
                            alt="Uploaded"
                            className="w-24 h-24 object-cover rounded-md"
                        />
                    </div>
                )}

                <div>
                    <input
                        type="file"
                        onChange={handleFileUpload}
                        disabled={uploading}
                        accept="image/*"
                        className="hidden"
                        id={props.id}
                    />

                    <label htmlFor={props.id} className="w-full text-center cursor-pointer">
                        <p className="text-gray-600">Drag & drop an image here, or click to select</p>
                        <div
                            className="mt-2 py-2 bg-[#4995CF] text-white rounded-md transition w-[200px] mx-auto"
                        >
                            {props.label}
                        </div>
                    </label>

                    {uploading && <p className="text-blue-500 mt-2">Uploading image...</p>}

                    {error && <p className="text-red-500 mt-2">{error}</p>}

                </div>
            </div>
        </div>
    );

    // return (
    //     <div
    //         className={`w-full border-2 border-dashed p-4 flex items-center justify-center rounded-md transition-colors ${isDragOver ? 'border-blue-500 bg-blue-50' : 'border-gray-300 bg-white'
    //             }`}
    //         onDragEnter={handleDragEnter}
    //         onDragLeave={handleDragLeave}
    //         onDragOver={handleDragOver}
    //         onDrop={handleDrop}
    //     >
    //         <input
    //             type="file"
    //             onChange={handleFileUpload}
    //             disabled={uploading}
    //             accept="image/*"
    //             className="hidden"
    //             id="file-upload"
    //         />
    //         <label
    //             htmlFor="file-upload"
    //             className="cursor-pointer flex flex-col items-center justify-center text-center"
    //         >
    //             <p className="text-gray-500">
    //                 {uploading ? 'Uploading...' : 'Drag and drop files here or click to upload'}
    //             </p>
    //             <button
    //                 type="button"
    //                 className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition"
    //             >
    //                 Browse Files
    //             </button>
    //         </label>
    //     </div>
    // )

    // return (
    //     <div className="flex flex-row">
    //         <input
    //             type="file"
    //             onChange={handleFileUpload}
    //             disabled={uploading}
    //             accept="image/*" // Restrict file types to images
    //         />

    //         {uploading && <p>Uploading image...</p>}

    //         {error && <p style={{ color: 'red' }}>{error}</p>}

    //         {imageUrl && (
    //             <div style={{ marginTop: '10px' }}>
    //                 <img
    //                     src={imageUrl}
    //                     alt="Uploaded"
    //                     style={{ width: '100px', height: '100px', objectFit: 'cover' }}
    //                 />
    //             </div>
    //         )}
    //     </div>
    // );
};
