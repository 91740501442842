import { addDoc, collection, doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Alert, Box, Card, Grid, Modal, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import { useLoadingOverlay } from "../../../loading";
import { AircraftCollection, NotificationsCollection, userKey, userNameKey } from "../../../keys";
import { CustomButton, CustomButtonNonSubmit } from "../../../components/button";
import { CustomButtonFill } from "../../../components/buttonfill";
import { DeleteConfirmModal } from "../../../components/confirmModel";

export const AddNotification = ({ aircraftId, notification, onClose }: { aircraftId: string, notification: any, onClose: () => void }) => {
    const [formValues, setFormValues] = useState({
        ...{
            "description": null,
            "notifyInDays": 0,
            "notifyInHours": 0,
        }, ...notification
    });
    const db = getFirestore()
    const { showOverlay, hideOverlay } = useLoadingOverlay();
    const [aircraft, setAircraft] = useState<any>();
    const [showConfirmation, setShowConfirmation] = useState(false)

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 3,
    };

    useEffect(() => { getAircraft() }, [])

    const handleChange = (e: any) => {

        const { name, value } = e.target;

        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleChangeCheckbox = (e: any) => {

        const { name, checked } = e.target;
        setFormValues({
            ...formValues,
            [name]: checked,
        });
    };

    const handleChangeNumber = (e: any) => {

        const { name, value } = e.target;

        setFormValues({
            ...formValues,
            [name]: parseFloat(value),
        });
    };

    const getAircraft = async () => {
        showOverlay()
        const db = getFirestore()
        const docRef = doc(db, AircraftCollection, aircraftId!);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var data = docSnap.data()
            setAircraft(data)
        }

        hideOverlay()
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        showOverlay();
        if (notification) {
            const notification = {
                ...formValues,
                userId: localStorage.getItem(userKey),
                userName: localStorage.getItem(userNameKey),
                // "date": new Date().toISOString().split("T")[0],
                // "aircraftTT": aircraft.totalTime,
                "aircraftId": aircraftId,
                "status": "Pending"
            }

            await setDoc(doc(db, NotificationsCollection, notification.id), notification)
        }
        else {
            //save
            const notification = {
                ...formValues,
                userId: localStorage.getItem(userKey),
                userName: localStorage.getItem(userNameKey),
                "date": new Date().toISOString().split("T")[0],
                "aircraftTT": aircraft?.totalTime,
                "aircraftId": aircraftId,
                "status": "Pending"
            }

            await addDoc(collection(db, NotificationsCollection), notification);
        }
        hideOverlay()

        onClose();
    };

    const cancelMxMinder = async () => {
        showOverlay()
        const notification = {
            ...formValues,
            userId: localStorage.getItem(userKey),
            userName: localStorage.getItem(userNameKey),
            // "date": new Date().toISOString().split("T")[0],
            // "aircraftTT": aircraft.totalTime,
            "aircraftId": aircraftId,
            "status": "Canceled"
        }
        await setDoc(doc(db, NotificationsCollection, notification.id), notification)
        hideOverlay()
        onClose();
    }

    return (
        <>
            <Modal
                open={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Card className='signup-card w-[90vw]' sx={style}>
                    <div className='flex  justify-between'>
                        <h1 className="text-3xl mb-5">{notification ? "Edit" : "Add"} New MX Minder</h1>

                        <CloseIcon className='hover:cursor-pointer' onClick={() => onClose()}></CloseIcon>
                    </div>

                    <Alert severity="info">
                        <span className=" mb-4">Notification Will be recurring until canceled.</span ><br />
                        <span className=" mb-4">Notification Start Date:  {notification ? notification.date : new Date().toISOString().split("T")[0]}</span><br />
                        <span className=" mb-4">Notification start Airframe TT: {notification ? notification.aircraftTT : aircraft?.totalTime}</span>
                    </Alert>

                    <Box
                        component="form"
                        sx={{ flexGrow: 1 }}
                        onSubmit={handleSubmit}
                        className="mt-4"
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Description"
                                    name="description"
                                    value={formValues.description}
                                    onChange={handleChange}
                                    className="w-full custom-textarea"
                                    rows={2}
                                    multiline
                                    required
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <div>
                                    <h6 className="font-bold">Notify Me <AddAlertIcon />
                                        {
                                            formValues.notifyInDays > 0 && formValues.notifyInHours > 0 ?
                                                ("(" + formValues.notifyInDays + " Days" + " or " + formValues.notifyInHours + " Hours - Whichever comes first)") :
                                                formValues.notifyInDays > 0 ? ("(" + formValues.notifyInDays + " Days" + ")") :
                                                    formValues.notifyInHours > 0 ? ("(" + formValues.notifyInHours + " hours )") : ""

                                        }
                                    </h6>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container xs={12} spacing={1} className="pt-1" >
                                    <Grid item xs={6} >
                                        <TextField
                                            label="In Days"
                                            name="notifyInDays"
                                            type="number"
                                            value={formValues.notifyInDays}
                                            onChange={handleChangeNumber}
                                            className="w-full"
                                        />
                                    </Grid>
                                    <Grid item xs={6} >
                                        <TextField
                                            label="In Hours"
                                            name="notifyInHours"
                                            type="number"
                                            value={formValues.notifyInHours}
                                            onChange={handleChangeNumber}
                                            className="w-full"
                                        />
                                    </Grid>
                                </Grid>
                                <div className="flex-1 border-t border-gray-300 mt-3"></div>
                            </Grid>

                        </Grid>

                        <div className="mt-5 flex gap-2">
                            <CustomButtonFill text='Submit' onClick={() => { }} />
                            {notification && <CustomButtonNonSubmit text='Cancel MX Minder'
                                onClick={() => {
                                    setShowConfirmation(true)
                                }}
                            />}
                        </div>
                    </Box>
                </Card>
            </Modal>
            {
                showConfirmation && <DeleteConfirmModal title="Delete MX Minder" onClose={() => { setShowConfirmation(false) }} onDelete={() => {
                    cancelMxMinder()
                }} />
            }
        </>)
}


// notifications
// days from today - or - hours from current tt

// AD notification
// days from last complied - or - houes from last compiled tt