import { useQuery, useQueryClient } from "@tanstack/react-query";
import { MxLogModel } from "../models/mx";
import { queryKeyGetMxById } from "../keys";
import { getMx_firebase } from "../services/mx";

export const useMxById = (mxId: string) => {
    const queryClient = useQueryClient()

    const query = useQuery<MxLogModel | null>({
        queryKey: [queryKeyGetMxById, mxId], queryFn: () => {
            if (mxId)
                return getMx_firebase(mxId)

            return null
        }
    })

    const invalidateMx = () => {
        queryClient.invalidateQueries({ queryKey: [queryKeyGetMxById, mxId] });
    };

    return {
        ...query,
        refreshMx: invalidateMx,
    };
};