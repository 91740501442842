import { cn } from "../userData/userData"

export const CustomButtonFill = ({ text, disabled, onClick }: { text: string, disabled?: boolean, onClick: () => void }) => {
    return <button
        className={cn("border border-[#4995CF] border-[1px] rounded-full h-[36px] w-[180px] align-center text-[#ffffff]", disabled ? "bg-[grey]" : "bg-[#3A8CC9]")}
        onClick={() => onClick()} disabled={disabled}>{text}</button>
}

export const CustomButtonSmallFill = ({ text, disabled, onClick }: { text: string, disabled?: boolean, onClick: () => void }) => {
    return <button
        className="border border-[#4995CF] border-[1px] rounded-full h-[26px] w-[60px] align-center bg-[#3A8CC9] text-[#ffffff] text-[12px]"
        onClick={() => onClick()} disabled={disabled}>
        {text}
    </button>
}
