export const UsersCollection: string = "Users";
export const AircraftCollection: string = "Aircrafts";
export const PilotLogbookCollection: string = "PilotLogbook";
export const PilotLogbookBase: string = "PilotLogbookBase";
export const MxLogbookCollection: string = "MxLogbookCollection";
export const MxLogbookTemplateCollection: string = "MxLogbookTemplateCollection";
export const CounterCollection: string = "Counter";
export const SquawkCollection: string = "Squawk";
export const AircraftADCompliancesCollection: string = "AircraftADCompliances";
export const ADCompliancesCollection: string = "ADCompliances";
export const NotificationsCollection: string = "Notifications";
export const PermissionsCollection: string = "Permissions";
export const AircraftMxLogAttachmentsCollection: string = "AircraftMxLogAttachments";
export const PilotLogAttachmentsCollection: string = "PilotLogAttachments";

export const userKey: string = 'user';
export const userIdKey: string = 'userId';
export const userNameKey: string = 'userName';
export const pilotShowHide: string = 'pilotShowHide';
export const aircraftShowHide: string = 'aircraftShowHide';

export const queryKeyUser = "User"
export const queryKeyAircraftsOfUser = "AircraftsOfUser"
export const queryKeyAircraftsOfPilot = "AircraftsOfPilot"
export const queryKeyAircraftsOfOwner = "AircraftsOfOwner"
export const queryKeyAircraftById = "AircraftById"
export const queryKeyPilotsOfUser = "PilotsOfUser"
export const queryKeyPermissionOfAircraft = "PermissionOfAircraft"
export const queryKeyPermissionOfUser = "PermissionOfUser"
export const queryKeyAircraftPermissionOfUser = "AircraftPermissionOfUser"
export const queryKeyGetMxById = "MxById"
export const queryKeyAircraftMXAttachments = "AircraftMXAttachments"
export const queryKeyPilotLogAttachments = "PilotLogAttachments"
export const queryKeyAircraftSquawks = "AircraftSquawks"