import { useEffect, useState } from "react";
import { AddSquawk } from "./addSquawkModal";
import { Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import AddSquawkIcon from '../../../assets/icons/AddSquawkIcon.svg';
import { InfoSeverityIcon, Severity } from '../../../components/severity';
import { cn } from '../../../userData/userData';
import { AddMxLog } from "../mx/addMxLog";
import { SquawksModal } from "./squawksModal";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import EditIcon from '@mui/icons-material/Edit';
import { useAircraftSquawks } from "../../../hooks/useSquawk";

export const SquawkList = ({ aircraftId, permissionPower, hideView = false, onCloseMx, setSquawkSeverity }: {
    aircraftId: string,
    permissionPower: number,
    hideView?: boolean,
    onCloseMx: () => void, setSquawkSeverity: (severity: Severity) => void
}) => {
    const [showAddModal, setShowAddModal] = useState(false);
    const [showConfimComplete, setShowConfimComplte] = useState(false);
    const [selected, setSelected] = useState<any[]>([]);
    const [showSquawks, setShowSquawks] = useState(false);

    const { data: squawks, refreshAircraftSquawks } = useAircraftSquawks(aircraftId)

    useEffect(() => {
        if (squawks)
            setSquawkSeverity(squawks.some((a: any) => a.severity == "High") ? Severity.High : squawks.some((a: any) => a.severity == "Medium") ? Severity.Medium : Severity.Low);
    }, [squawks])

    // type PriorityType = "High" | "Medium" | "Low";
    // const priority = { "High": 1, "Medium": 2, "Low": 3 };

    // const getAllSquawks = async () => {
    //     const db = getFirestore()
    //     const data: any[] = [];

    //     const q = query(collection(db, SquawkCollection), where("status", "==", "Pending"), where("aircraftId", "==", aircraftId));
    //     const querySnapshot = await getDocs(q);
    //     querySnapshot.forEach((doc) => {
    //         data.push({ ...doc.data(), id: doc.id });
    //     });
    //     data.sort((a: any, b: any) => priority[a.severity as PriorityType] - priority[b.severity as PriorityType]);

    //     setSquawkSeverity(data.some((a: any) => a.severity == "High") ? Severity.High : data.some((a: any) => a.severity == "Medium") ? Severity.Medium : Severity.Low);
    //     setSquawks(data);
    // }

    const getSeverity = (severity: string) => {
        return severity == "High" ? Severity.High : severity == "Medium" ? Severity.Medium : Severity.Low
    }

    return <>
        <div className="pr-2">
            <div className="flex py-1 mb-3 sticky top-0 bg-gray-100 z-10">
                <span className="text-[14px] font-bold">SQUAWKS</span>
                <div className="mt-[-3px] ml-3">
                    {!hideView && <Tooltip title="View squawks" arrow>
                        <div className="w-[25px] cursor-pointer" onClick={() => { setShowSquawks(true) }} ><img src={AddSquawkIcon} /></div>
                    </Tooltip>}
                </div>
                <div className="mt-[-3px] ml-3">
                    {
                        permissionPower >= 3 && < Tooltip title="Add squawk" arrow>
                            <div className="w-[25px] cursor-pointer" onClick={() => { setShowAddModal(true) }} ><PlaylistAddIcon /></div>
                        </Tooltip>
                    }
                </div>
                {selected.length > 0 && <button
                    className="ml-auto border border-[#4995CF] border-[1px] rounded-full h-[26px] px-2 align-center bg-[#3A8CC9] text-[#ffffff] text-[12px]"
                    onClick={() => { setShowConfimComplte(true) }}>
                    Fix Squawk(s) - ({selected.length})
                </button>}
            </div>

            {squawks && squawks.map((value, i) => {
                return <div key={i} className={cn("flex flex-col md:flex-row border-2 p-[2px] rounded-md mb-1 border-orange-500")}>
                    <div className="flex flex-row items-center w-[100%]">
                        <InfoSeverityIcon severity={getSeverity(value.severity)} />
                        <p className="text-gray-700 text-[12px] ml-3"><b>{value.squawkNumber}</b></p>
                        <p className="text-gray-700 text-[12px] ml-2">{value.date}</p>
                        <p className="flex-1 text-gray-700 text-[12px] ml-2 hidden md:block">{value.description}</p>
                        <p className="text-gray-700 text-[12px] ml-2"><b>{value.severity}</b></p>
                        <p className="text-gray-700 text-[12px] ml-2 mr-2 hidden md:block">{value.pilot}</p>
                        {permissionPower >= 3 && <EditIcon sx={{ width: "20px" }} className="ml-1 mr-2 p-0 cursor-pointer" onClick={() => {
                            setSelected([value])
                            setShowAddModal(true)
                        }} />}
                        {permissionPower >= 4 && <div className="cursor-pointer ml-auto">
                            <FormControlLabel
                                control={<Checkbox size="small" checked={selected.some((ele: any) => ele.id === value.id)} onChange={async (e: any) => {
                                    if (e.target.checked) {
                                        await setSelected([...selected, value])
                                    } else {
                                        await setSelected(selected.filter((ele: any) => ele.id !== value.id))
                                    }
                                }} name="fixSquawk" />}
                                label=""
                            />
                        </div>}
                    </div>

                    <p className="text-gray-700 text-[12px] md:mt-0 md:ml-2 block md:hidden">{value.description}</p>

                    <p className="text-gray-700 text-[12px] mr-2 block md:hidden">{value.pilot}</p>
                </div>
                // return <div key={i} className={cn("flex flex-col md:flex-row items-start md:items-center border-2 p-[2px] rounded-md mb-1", "border-orange-500")}>
                //     <div className="flex flex-row">
                //         <InfoSeverityIcon severity={getSeverity(value.severity)} />
                //         <p className="text-gray-700 text-[12px] ml-3"><b>{value.squawkNumber}</b></p>
                //         <p className="text-gray-700 text-[12px] ml-2">{value.date}</p>
                //         <p className="flex-1 text-gray-700 text-[12px] ml-2 hidden md:block">{value.description}</p>
                //         <p className="text-gray-700 text-[12px] ml-2"><b>{value.severity}</b></p>
                //         <p className="text-gray-700 text-[12px] ml-2 mr-2 hidden md:block">{value.pilot}</p>
                //         {permissionPower >= 4 && <div className="cursor-pointer ">
                //             <FormControlLabel
                //                 control={<Checkbox size="small" checked={selected.some((ele: any) => ele.id === value.id)} onChange={async (e: any) => {
                //                     if (e.target.checked) {
                //                         await setSelected([...selected, value])
                //                     } else {
                //                         await setSelected(selected.filter((ele: any) => ele.id !== value.id))
                //                     }
                //                 }} name="fixSquawk" />}
                //                 label=""
                //             />
                //         </div>}
                //     </div>

                //     <p className="text-gray-700 text-[12px] md:mt-0 md:ml-2 block md:hidden">{value.description}</p>

                //     <p className="text-gray-700 text-[12px] mr-2 block md:hidden">{value.pilot}</p>
                // </div>
            })
            }

            {
                squawks && squawks.length == 0 && <p className="text-[13px] text-[green]">No overdue squawks</p>
            }

            {
                showAddModal && <AddSquawk aircraftId={aircraftId} existingSquawk={selected[0]} onClose={() => {
                    setShowAddModal(false)
                    refreshAircraftSquawks()
                    setSelected([])
                }} />
            }

            {
                showConfimComplete && <AddMxLog onClose={() => {
                    onCloseMx()
                    setSelected([])
                    setShowConfimComplte(false)
                    refreshAircraftSquawks()
                }} aircraftId={aircraftId}
                    descriptionPreFill={"Pilot reported " + selected.map(item => item.description).join(", ") + "- RESOLVED"}
                    squawk={selected}
                />
            }


            {showSquawks && <SquawksModal aircraftId={aircraftId} permissionPower={permissionPower} onClose={() => { setShowSquawks(false) }} />}
        </div>
    </>
}


// const CompleteSquawk = ({ squawk, onClose }: { squawk: any, onClose: () => void }) => {
//     const { showOverlay, hideOverlay } = useLoadingOverlay();

//     const style = {
//         position: 'absolute' as 'absolute',
//         top: '50%',
//         left: '50%',
//         transform: 'translate(-50%, -50%)',
//         bgcolor: 'background.paper',
//         p: 3,
//     };

//     return <Modal
//         open={true}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//     >
//         <Card className='' sx={style}>
//             <div className='flex  flex-row justify-between'>
//                 <h1 className="text-3xl mb-5">Complete SQUAWK</h1>
//                 <CloseIcon className='hover:cursor-pointer' onClick={() => onClose()}></CloseIcon>
//             </div>
//             <h3>
//                 Click ok to complete squawk as completed.
//             </h3>

//             <div className="flex mt-5 gap-3">
//                 <CustomButtonFill text='Yes' onClick={async () => {
//                     showOverlay()
//                     const db = getFirestore()
//                     let squawkToSave = {
//                         ...squawk,
//                         status: "Completed"
//                     }
//                     console.log(squawk)

//                     await setDoc(doc(db, SquawkCollection, squawk.id), squawkToSave)
//                     hideOverlay()
//                     onClose()
//                 }} />
//                 <CustomButton text="No" onClick={() => onClose()} />
//             </div>

//         </Card>
//     </Modal>
// }