import InfoIcon from '@mui/icons-material/Info';
import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { AircraftADCompliancesCollection } from "../../../keys";
import { Checkbox, FormControlLabel } from '@mui/material';
import { useLoadingOverlay } from '../../../loading';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { getSeverityBorderColor, getSeverityWithDateAndHours, getSeverityWithDateAndHoursString, InfoNotificationSeverityIcon, Severity } from '../../../components/severity';
import { cn } from '../../../userData/userData';
import { AddMxLog } from '../mx/addMxLog';

export const AdList = ({ aircraftId, showAll, tt, tachTime, tsmoh, limit, permissionPower, onCloseMx, setAdSeverity }:
    { aircraftId: string, showAll: boolean, tt: number, tsmoh: number, tachTime: number, limit?: number, permissionPower: number, onCloseMx: () => void, setAdSeverity: (severity: Severity) => void }) => {
    const [ads, setAds] = useState<any[]>([])
    const [sheduledAds, setScheduledAds] = useState<any[]>([])
    const [showAddModal, setShowAddModal] = useState(false);
    const [showConfimComplete, setShowConfimComplte] = useState(false);
    const [selected, setSelected] = useState<any[]>([]);
    const { showOverlay, hideOverlay } = useLoadingOverlay();

    useEffect(() => {
        getAllAds()
    }, [])

    const getAllAds = async () => {
        const db = getFirestore()
        const data: any[] = [];
        const scheduledData: any[] = [];

        const q = query(collection(db, AircraftADCompliancesCollection), where("aircraftId", "==", aircraftId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            if (doc.data().cw === false || doc.data().cw === undefined) {
                data.push({ ...doc.data(), id: doc.id });
            }

            if (doc.data().notifyInDays > 0 || doc.data().notifyInHours > 0) {
                let tempData = { ...doc.data(), id: doc.id, severity: Severity.Low, scheduleString: "" };
                const severity = getSeverityWithDateAndHours(
                    doc.data().notifyInDays,
                    doc.data().complianceDate ?? new Date().toISOString().split("T")[0],
                    doc.data().notifyInHours,
                    doc.data().aircraftTT ?? 0, tt)
                const scheduleString = getSeverityWithDateAndHoursString(
                    doc.data().notifyInDays,
                    doc.data().complianceDate ?? new Date().toISOString().split("T")[0],
                    doc.data().notifyInHours,
                    doc.data().aircraftTT ?? 0, tt)
                tempData = { ...tempData, severity: severity, scheduleString: scheduleString }
                scheduledData.push(tempData)
            }
        });
        setAds(data);
        scheduledData.sort((a: any, b: any) => b.severity - a.severity);
        setScheduledAds(scheduledData);
        setAdSeverity(scheduledData.some((a: any) => a.severity == Severity.High) ? Severity.High : data.some((a: any) => a.severity == Severity.Medium) ? Severity.Medium : Severity.Low);
    }

    const filteredNotifications = showAll
        ? sheduledAds
        : sheduledAds.filter((notification) => notification.severity !== Severity.Done);

    return <>
        <div className="pr-2">
            <div className="flex py-1 sticky top-0 bg-gray-100 z-10">
                <span className="text-[14px] font-bold">AD Compliance</span>
                {/* <div className="mt-[-3px] ml-3">
                    <div className="w-[25px] cursor-pointer" onClick={() => { setShowAddModal(true) }} ><img src={AddAdIcon} /></div>
                </div> */}
                {selected.length > 0 && <button
                    className="ml-auto border border-[#4995CF] border-[1px] rounded-full h-[26px] px-2 align-center bg-[#3A8CC9] text-[#ffffff] text-[12px]"
                    onClick={() => { setShowConfimComplte(true) }}>
                    C/W AD(s) - ({selected.length})
                </button>}
            </div>

            <span className='font-bold text-[13px]'>Scheduled <NotificationsActiveIcon /></span>
            {filteredNotifications.map((value, i) => {
                return <div key={i} className={cn("flex flex-col md:flex-row items-center border-2  p-[2px] rounded-md mb-1", getSeverityBorderColor(value.severity))}>
                    <div className="flex flex-row items-center w-[100%]">
                        <InfoNotificationSeverityIcon severity={value.severity} />
                        <p className="text-gray-700 text-[12px] ml-3">
                            <a href={value.documentURL} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
                                <b>{value.documentNumber}</b>
                            </a>
                        </p>
                        <p className="flex-1 text-gray-700 text-[12px] ml-2 hidden md:block">
                            {value.adfrawdSubject} - {value.type} - <b>{value.scheduleString}</b>
                        </p>
                        <p className="text-gray-700 text-[12px] ml-2 mr-2"><b>{value.effectiveDate?.split("T")[0]}</b></p>
                        {permissionPower >= 4 && <div className="cursor-pointer ml-auto">
                            <FormControlLabel
                                control={<Checkbox size="small" checked={selected.some((ele: any) => ele.id === value.id)} onChange={async (e: any) => {
                                    if (e.target.checked) {
                                        await setSelected([...selected, value])
                                    } else {
                                        await setSelected(selected.filter((ele: any) => ele.id !== value.id))
                                    }
                                }} name="fixSquawk" />}
                                label=""
                            />
                        </div>}
                    </div>

                    <p className="text-gray-700 text-[12px] md:mt-0 md:ml-2 block md:hidden">{value.adfrawdSubject} - {value.type} - <b>{value.scheduleString}</b></p>
                </div>
            })}
            <div className="flex-1 border-t border-gray-300 my-2"></div>

            <span className='font-bold text-[13px]'>Other not C/W</span>
            {ads.map((value, i) => {
                return <div key={i} className="flex flex-col md:flex-row items-center border-2 border-orange-500 p-[2px] rounded-md mb-1">
                    <div className="flex flex-row items-center w-[100%]">
                        <InfoIcon className="text-orange-500 mr-3" />
                        <p className="text-gray-700 text-[12px]">
                            <a href={value.documentURL} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
                                <b>{value.documentNumber}</b>
                            </a>
                        </p>
                        <p className="flex-1 text-gray-700 text-[12px] ml-2 hidden md:block">{value.adfrawdSubject} - {value.type} </p>
                        <p className="text-gray-700 text-[12px] ml-2 mr-2"><b>{value.effectiveDate?.split("T")[0]}</b></p>
                        {permissionPower >= 4 && <div className="cursor-pointer ml-auto">
                            <FormControlLabel
                                control={<Checkbox size="small" checked={selected.some((ele: any) => ele.id === value.id)} onChange={async (e: any) => {
                                    if (e.target.checked) {
                                        await setSelected([...selected, value])
                                    } else {
                                        await setSelected(selected.filter((ele: any) => ele.id !== value.id))
                                    }
                                }} name="fixSquawk" />}
                                label=""
                            />
                        </div>}
                    </div>

                    <p className="text-gray-700 text-[12px] md:mt-0 md:ml-2 block md:hidden">{value.adfrawdSubject} - {value.type}</p>
                </div>
            })}

            {
                ads.length == 0 && <p className="text-[13px] text-[green]">No overdue AD</p>
            }

            {
                showConfimComplete && <AddMxLog onClose={() => {
                    onCloseMx()
                    setSelected([])
                    setShowConfimComplte(false)
                    getAllAds()
                }} aircraftId={aircraftId}
                    descriptionPreFill={"C/W AD " + selected.map(item => item.documentNumber + " " + item.adfrawdSubject).join(", ")}
                    ad={selected} />
            }
        </div>
    </>
}
