import { Card, MenuItem, Select } from "@mui/material"
import { collection, doc, getDoc, getDocs, getFirestore, query, setDoc, where } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { AircraftADCompliancesCollection, AircraftCollection } from "../../../keys";
import { useLoadingOverlay } from "../../../loading";
import { AdDueDateSummary, AdSummary } from "./adSummary";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import EditIcon from '@mui/icons-material/Edit';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { AddEditAd } from "./addEditAd";
import { CustomButtonSmallFill } from "../../../components/buttonfill";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { MaterialReactTable, MRT_ColumnDef, useMaterialReactTable } from "material-react-table";
import { AddAdReminder } from "./notifyAd";
import { CustomBack } from "../../../components/backbutton";
import { CustomButtonSmall } from "../../../components/button";
import SyncIcon from '@mui/icons-material/Sync';
import { useSnackbar } from "../../../snackbar";
import { useUserAircraftPermissions } from "../../../hooks/usePermissions";
import { ExternalLink } from "../../../components/externalLink";

export const AdCompliances = ({ backTo = '/' }: { backTo?: string }) => {
    const [airframeAds, setAirframeAds] = useState<any[]>([]);
    const [engineAds, setEngineAds] = useState<any[]>([]);
    const [propellerAds, setPropellerAds] = useState<any[]>([]);
    const { aircraftId } = useParams();
    const { showOverlay, hideOverlay } = useLoadingOverlay();
    const [aircraft, setAircraft] = useState<any>();
    const [showSummary, setShowSummary] = useState(false);
    const [showDueDateSummary, setShowDueDateSummary] = useState(false);
    const [selectedAd, setSelectedAd] = useState<any | null>();
    const [showAddEdit, setShowAddEdit] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [showAddReminder, setShowAddReminder] = useState(false)
    const { permissionPower } = useUserAircraftPermissions(aircraft?.id, aircraft?.addedUser);

    const { showSnackbar } = useSnackbar();

    useEffect(() => {
        getAllAds()
        getAircraft()
    }, [])

    const getAircraft = async () => {
        showOverlay()
        const db = getFirestore()
        const docRef = doc(db, AircraftCollection, aircraftId!);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            setAircraft({ ...docSnap.data(), id: docSnap.id })
        }

        hideOverlay()
    }

    const onClickEdit = () => {
        setIsEdit(true)
        setShowAddEdit(true)
    }

    const getAllAds = async () => {
        showOverlay()
        const db = getFirestore()
        const data: any[] = [];

        const q = query(collection(db, AircraftADCompliancesCollection), where("aircraftId", "==", aircraftId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            data.push({ ...doc.data(), id: doc.id });
        });

        setAirframeAds(data.filter(a => a.type == "Aircraft"));

        setEngineAds(data.filter(a => a.type == "Engine" || a.type == "Engine2"));
        // engineAdsT.map(a => a.adDoc = adDocdata.find(d => d.documentGuid == a.adId))
        setPropellerAds(data.filter(a => a.type == "Propeller" || a.type == "Propeller"));

        hideOverlay()
    }

    const updateAircraftVersion = async () => {
        const db = getFirestore()

        let aircraftData = {
            ...aircraft,
            version: new Date().toString()
        }
        await setDoc(doc(db, AircraftCollection, aircraft.id), aircraftData)

        showSnackbar('ADs will be synced in the background. Please check them later!', 'success')
    }

    return <>
        <div>
            <Card className="mt-1">
                <CustomBack backTo={backTo} />
                <div className="font-[700] text-[22px] text-[#85144B] p-3 flex gap-3">{aircraft?.aircraftId} AD Compliance
                    {permissionPower >= 4 && <div className="ml-3 mt-[-2px]"><CustomButtonSmallFill text='Add +' onClick={() => {
                        setShowAddEdit(true)
                        setIsEdit(false)
                        setSelectedAd(null)
                    }} /></div>}
                    {permissionPower >= 4 && <div className="ml-1 mt-[-2px]"><CustomButtonSmall text='Sync ADs' onClick={async () => {
                        await updateAircraftVersion()
                    }} ><SyncIcon className="text-[12px]" /></CustomButtonSmall></div>}
                </div>
                <AdFilterTable
                    title={`Airframe Model: ${aircraft?.model} S/N: ${aircraft?.aircraftSerialNumber}`}
                    ads={airframeAds}
                    permissionPower={permissionPower}
                    setSelectedAd={setSelectedAd}
                    setShowDueDateSummary={setShowDueDateSummary}
                    setShowSummary={setShowSummary}
                    onClickEdit={onClickEdit}
                    onClickAddReminder={() => { setShowAddReminder(true) }} />


                <div className="h-[15px]"></div>
                <AdFilterTable
                    title={`Engine Model: ${aircraft?.engineModel} S/N: ${aircraft?.engineSerialNumber} ${aircraft?.engineModel_engine2 ? `| Engine 2 Model: ${aircraft?.engineModel_engine2} S/N: ${aircraft?.engineSerialNumber_engine2}` : ""}`}
                    ads={engineAds}
                    permissionPower={permissionPower}
                    setSelectedAd={setSelectedAd}
                    setShowDueDateSummary={setShowDueDateSummary}
                    setShowSummary={setShowSummary}
                    onClickEdit={onClickEdit}
                    onClickAddReminder={() => { setShowAddReminder(true) }} />

                <div className="h-[15px]"></div>
                <AdFilterTable
                    title={`Propeller Model: ${aircraft?.propellerModel} Hub S/N: ${aircraft?.propellerHubSerialNumber} Prop1 S/N: ${aircraft?.prop1SerialNumber} ${aircraft?.prop2SerialNumber ? `Prop2 S/N: ${aircraft?.prop2SerialNumber ?? '-'}` : ""} ${aircraft?.prop3SerialNumber ? `Prop3 S/N: ${aircraft?.prop3SerialNumber ?? '-'}` : ""}  ${aircraft?.prop4SerialNumber ? `Prop4 S/N: ${aircraft?.prop4SerialNumber ?? '-'}` : ""}  ${aircraft?.prop5SerialNumber ? `Prop5 S/N: ${aircraft?.prop5SerialNumber ?? '-'}` : ""}`}
                    title2={aircraft?.propellerModel_engine2 ? `Propeller 2 Model: ${aircraft?.propellerModel_engine2} Hub S/N: ${aircraft?.propellerHubSerialNumber_engine2} Prop1 S/N: ${aircraft?.prop1SerialNumber_engine2} ${aircraft?.prop2SerialNumber_engine2 ? `Prop2 S/N: ${aircraft?.prop2SerialNumber_engine2 ?? '-'}` : ""} ${aircraft?.prop3SerialNumber_engine2 ? `Prop3 S/N: ${aircraft?.prop3SerialNumber_engine2 ?? '-'}` : ""}  ${aircraft?.prop4SerialNumber_engine2 ? `Prop4 S/N: ${aircraft?.prop4SerialNumber_engine2 ?? '-'}` : ""}  ${aircraft?.prop5SerialNumber_engine2 ? `Prop5 S/N: ${aircraft?.prop5SerialNumber_engine2 ?? '-'}` : ""}` : ""}
                    ads={propellerAds}
                    permissionPower={permissionPower}
                    setSelectedAd={setSelectedAd}
                    setShowDueDateSummary={setShowDueDateSummary}
                    setShowSummary={setShowSummary}
                    onClickEdit={onClickEdit}
                    onClickAddReminder={() => { setShowAddReminder(true) }} />
            </Card>

            {
                showSummary && <AdSummary log={selectedAd} onClose={() => {
                    setSelectedAd(null)
                    setShowSummary(false)
                }} />
            }

            {
                showDueDateSummary && <AdDueDateSummary log={selectedAd} onClose={() => {
                    setSelectedAd(null)
                    setShowDueDateSummary(false)
                }} />
            }

            {
                showAddEdit && <AddEditAd onClose={() => {
                    setSelectedAd(null)
                    setShowAddEdit(false)
                    getAllAds()
                    setIsEdit(false)
                }} data={selectedAd} isEditMode={isEdit} aircraft={aircraft} />
            }
            {
                showAddReminder && <AddAdReminder ad={selectedAd} onClose={() => {
                    setSelectedAd(null)
                    setShowAddReminder(false)
                    getAllAds()
                }} />
            }
        </div>
    </>
}

const styleProps = {
    muiTableHeadCellProps: {
        sx: {
            backgroundColor: '#3A8CC9',
            color: 'white',
        },
    },
}
const AdFilterTable = ({ title, title2, ads, permissionPower, setSelectedAd, setShowSummary, setShowDueDateSummary, onClickEdit, onClickAddReminder }: {
    title: string,
    title2?: string,
    ads: any[],
    permissionPower: number,
    setSelectedAd: (log: any) => void,
    setShowSummary: (x: boolean) => void,
    setShowDueDateSummary: (x: boolean) => void,
    onClickEdit: () => void,
    onClickAddReminder: () => void
}) => {
    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: 'documentNumber',
                header: 'AD Number',
                maxSize: 0.7,
                Cell: ({ renderedCellValue, row }) => (
                    <p className="text-gray-700 text-[12px]">
                        <a href={row?.original.documentURL} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
                            <b>{renderedCellValue}</b>
                        </a>
                        {/* <EditIcon sx={{ width: "15px" }} className="ml-3 p-0 cursor-pointer" onClick={() => {
                            setSelectedAd(row.original)
                            onClickEdit()
                        }} /> */}
                        {row.original?.cw && <CheckCircleIcon className="text-[green] ml-1" />}
                    </p>

                ),
                ...styleProps
            },
            {
                id: "Actions",
                header: "Actions",
                maxSize: 0.5,
                Cell: ({ renderedCellValue, row }) => (
                    <p className="text-gray-700 text-[12px]">
                        <a href={row?.original.documentURL} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
                            <b>{renderedCellValue}</b>
                        </a>
                        {permissionPower >= 4 && <EditIcon sx={{ width: "20px" }} className="ml-1 mr-2 p-0 cursor-pointer" onClick={() => {
                            setSelectedAd(row.original)
                            onClickEdit()
                        }} />}

                        {
                            (row.original.notifyInDays > 0 || row.original.notifyInHours > 0) && <NotificationsActiveIcon />
                        }

                        {/* <AddAlertIcon sx={{ width: "20px" }} className=" cursor-pointer" onClick={() => {
                            setSelectedAd(row.original)
                            onClickAddReminder()
                        }} /> */}
                    </p>

                ),
                ...styleProps
            },

            {
                accessorKey: 'effectiveDate',
                header: 'Effective Date',
                Cell: ({ renderedCellValue, row }) => (
                    <>
                        {row.original?.effectiveDate?.split("T")[0]}
                    </>
                ),
                ...styleProps
            },
            {
                accessorKey: 'adfrawdSubject',
                header: 'Subject',
                Cell: ({ renderedCellValue, row }) => (
                    <div className="" onClick={() => {
                        setSelectedAd(row.original)
                        setShowSummary(true)
                    }}>
                        {row.original?.adfrawdSubject} <OpenInNewIcon sx={{ width: "15px" }} className="ml-3 p-0 cursor-pointer" /></div>

                ),
                maxSize: 5,
                ...styleProps
            },
            {
                accessorKey: 'dueDate',
                header: 'Due date/hours',
                Cell: ({ renderedCellValue, row }) => (
                    <>
                        {row.original.dueDate ?? "-"} <InfoIcon sx={{ width: "15px" }} className="ml-2  cursor-pointer" onClick={() => {
                            setSelectedAd(row.original)
                            setShowDueDateSummary(true)
                        }} />
                    </>
                ),
                ...styleProps
            },
            {
                accessorKey: 'complianceDate',
                header: 'Compliance Date',
                ...styleProps
            },
            {
                accessorKey: 'actionTaken',
                header: 'Action Taken',
                ...styleProps
            },
            {
                accessorKey: 'isRecurring',
                header: 'Recurring',
                filterFns: {
                    equals: (row: any, columnId: any, filterValue: any) => {
                        const value = row.getValue(columnId);
                        // Handle undefined or null values
                        if (value === undefined || value === null) {
                            return filterValue === "" || filterValue === undefined;
                        }
                        return filterValue ? value.toString() === filterValue : true;
                    },
                },
                Filter: ({ column }) => {
                    const { getFilterValue, setFilterValue } = column;
                    const filterValue = getFilterValue();

                    return (
                        <Select
                            value={filterValue || ""}
                            onChange={(e) => setFilterValue(e.target.value || undefined)} // undefined removes the filter
                            displayEmpty
                            fullWidth
                            style={{ height: "30px" }}
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="true">Yes</MenuItem>
                            <MenuItem value="false">No</MenuItem>
                        </Select>
                    );
                },
                Cell: ({ renderedCellValue, row }) => (
                    <>
                        {!row.original.dueDateExplanation ? "-" : row.original.isRecurring ? "Yes" : "No"}
                    </>
                ),
                ...styleProps
            },
            {
                accessorKey: 'recurringFrequency',
                header: 'Recurring Frequency',
                Cell: ({ renderedCellValue, row }) => (
                    <>
                        {row.original.recurringFrequency ? '(' + row.original.recurringFrequency + ')' : ""}
                    </>
                ),
                ...styleProps
            },
            {
                accessorKey: 'additionalLinks',
                header: 'Additional Links',
                Cell: ({ renderedCellValue, row }) => (
                    <>
                        {row.original?.additionalLinks?.map((l: any, index: number) => (
                            <ExternalLink key={index} title={l.title} url={l.url} />
                        ))}
                    </>
                ),
                ...styleProps
            },
            {
                accessorKey: 'maintainerName',
                header: 'Maintainers Name',
                ...styleProps
            },
            {
                accessorKey: 'maintainerLicenseCertificateNumber',
                header: 'certificate #',
                ...styleProps
            },
        ],
        [permissionPower],
    );

    const table = useMaterialReactTable({
        columns,
        data: ads,
        initialState: { showColumnFilters: true, density: "compact", },
    });

    return <div className="relative">
        <MaterialReactTable table={table} />
        <span className="font-bold ml-3 text-[18px] mt-1 absolute top-0 z-[10]">{title}</span>
        <span className="font-bold ml-3 text-[18px] mt-2 absolute top-5 z-[10]">{title2}</span>
    </div>;
};


//export const AdTable = ({ ads, setSelectedAd, setShowSummary, setShowDueDateSummary, onClickEdit }: {
//     ads: any[],
//     setSelectedAd: (log: any) => void,
//     setShowSummary: (x: boolean) => void,
//     setShowDueDateSummary: (x: boolean) => void,
//     onClickEdit: () => void
// }) => {
//     const [actionTakenFilter, setActionTakenFilter] = useState("");
//     const [maintainerNameFilter, setMaintainerNameFilter] = useState("");
//     const [subjectFilter, setSubjectFilter] = useState("");

//     const getFilteredAds = () => {
//         console.log(actionTakenFilter, "$#%#%$#%")
//         let filteredAd = ads;

//         if (actionTakenFilter) {
//             filteredAd = filteredAd.filter(a => a.actionTaken?.toLowerCase().includes(actionTakenFilter))
//         }
//         if (maintainerNameFilter) {
//             filteredAd = filteredAd.filter(a => a.maintainerName?.toLowerCase().includes(maintainerNameFilter))
//         }
//         if (subjectFilter) {
//             filteredAd = filteredAd.filter(a => a.adfrawdSubject?.toLowerCase().includes(subjectFilter))
//         }

//         return filteredAd;
//     }

//     return <table className="pt-5 w-[100%] border-2 border-[#CCCCCC]">
//         <tbody>
//             <tr className="bg-[#3A8CC9] p-3 w-[100%] h-[57px]">
//                 <th className="font-[600] text-[10px] text-[#FFFFFF] border">AD Number</th>
//                 <th className="font-[600] text-[10px] text-[#FFFFFF] border">Additional Links</th>
//                 <th className="font-[600] text-[10px] text-[#FFFFFF] border">Effective Date</th>
//                 <th className="font-[600] text-[10px] text-[#FFFFFF] border pt-3">
//                     Subject <FilterText variable={subjectFilter} setVariable={setSubjectFilter} />
//                 </th>
//                 <th className="font-[600] text-[10px] text-[#FFFFFF] border max-w-[5vw]">Due date/hours</th>
//                 <th className="font-[600] text-[10px] text-[#FFFFFF] border">Compliance Date</th>
//                 <th className="font-[600] text-[10px] text-[#FFFFFF] border pt-3">
//                     Action Taken <FilterText variable={actionTakenFilter} setVariable={setActionTakenFilter} />
//                 </th>
//                 <th className="font-[600] text-[10px] text-[#FFFFFF] border max-w-[6vw]">Recurring</th>
//                 <th className="font-[600] text-[10px] text-[#FFFFFF] border pt-3">
//                     Maintainers Name <FilterText variable={maintainerNameFilter} setVariable={setMaintainerNameFilter} />
//                 </th>
//                 <th className="font-[600] text-[10px] text-[#FFFFFF] border max-w-[6vw]">Maintainers license certificate #</th>
//             </tr>
//             {
//                 getFilteredAds().map((log, index) => {

//                     return <tr className="h-[57px] text-center  border">
//                         <td className="border text-[12px]">
//                             <p className="text-gray-700 text-[12px]">
//                                 <a href={log?.documentURL} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
//                                     <b>{log?.documentNumber}</b>
//                                 </a>
//                                 <EditIcon sx={{ width: "15px" }} className="ml-3 p-0 cursor-pointer" onClick={() => {
//                                     setSelectedAd(log)
//                                     onClickEdit()
//                                 }} />
//                                 {log?.cw && <CheckCircleIcon className="text-[green] ml-1" />}
//                             </p>
//                         </td>
//                         <td className="border text-[12px] max-w-[6vw]">
//                             {log?.additionalLinks?.map((l: any, index: number) => (
//                                 <span key={index}>
//                                     <a href={l} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
//                                         <b>Link {index + 1}</b>
//                                     </a>
//                                     {index < log.additionalLinks.length - 1 && ", "}
//                                 </span>
//                             ))}
//                         </td>
//                         <td className="border text-[12px]">{log?.effectiveDate?.split("T")[0]}</td>
//                         <td className="border text-[12px] max-w-[20vw]">
//                             <div className="text-center" onClick={() => {
//                                 setSelectedAd(log)
//                                 setShowSummary(true)
//                             }}>
//                                 {log?.adfrawdSubject} <OpenInNewIcon sx={{ width: "15px" }} className="ml-3 p-0 cursor-pointer" /></div>
//                         </td>
//                         <td className="border text-[12px] max-w-[12vw]">{log.dueDate ?? "-"} <InfoIcon sx={{ width: "15px" }} className="ml-2  cursor-pointer" onClick={() => {
//                             setSelectedAd(log)
//                             setShowDueDateSummary(true)
//                         }} /></td>
//                         <td className="border text-[12px]">{log.complianceDate ?? "-"}</td>
//                         <td className="border text-[12px] max-w-[15vw]">{log.actionTaken ?? "-"}</td>
//                         <td className="border text-[12px] max-w-[10vw]">{!log.dueDateExplanation ? "-" : log.isRecurring ? "Yes" : "No"} {log.recurringFrequency ? '(' + log.recurringFrequency + ')' : ""}</td>
//                         <td className="border text-[12px] max-w-[6vw]">{log.maintainerName ?? "-"}</td>
//                         <td className="border text-[12px] max-w-[5vw]">{log.maintainerLicenseCertificateNumber ?? "-"}</td>
//                     </tr>
//                 })
//             }
//         </tbody>

//     </table>
// }

// export const FilterText = ({ variable, setVariable }: { variable: string, setVariable: (val: string) => void }) => {
//     return <div className="p-1">
//         <TextField
//             label="Filter"
//             value={variable}
//             onChange={(e) => setVariable(e.target.value)}
//             className="w-full text-white"
//             size="small"
//             sx={{
//                 input: { color: 'white' },
//                 label: { color: 'white' },
//                 '& .MuiOutlinedInput-root': {
//                     '& fieldset': { borderColor: 'white' },
//                     '&:hover fieldset': { borderColor: 'white' },
//                     '&.Mui-focused fieldset': { borderColor: 'white' },
//                 },
//             }}
//         />
//     </div>
// }