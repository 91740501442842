import { doc, getDoc, getFirestore } from "firebase/firestore";
import { MxLogModel } from "../models/mx";
import { MxLogbookCollection } from "../keys";

export const getMx_firebase = async (mxId: string): Promise<MxLogModel | null> => {
    const db = getFirestore()
    if (mxId) {
        const docRef = doc(db, MxLogbookCollection, mxId!);
        const docSnap = await getDoc(docRef);

        return docSnap.exists() ? { ...docSnap.data(), id: docSnap.id } as MxLogModel : null
    }
    return null;
}