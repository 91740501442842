import { addDoc, collection, doc, getDocs, getFirestore, setDoc } from "firebase/firestore";
import { useState } from "react";
import { useLoadingOverlay } from "../../../loading";
import { CounterCollection, SquawkCollection, userKey, userNameKey } from "../../../keys";
import { Box, Card, FormControl, Grid, InputLabel, MenuItem, Modal, Select, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { CustomButtonFill } from "../../../components/buttonfill";
import { squawkSeverity } from "../../../dropdownItems";
import { CustomButton, CustomButtonNonSubmit } from "../../../components/button";
import { DeleteConfirmModal } from "../../../components/confirmModel";

export const AddSquawk = ({ aircraftId, existingSquawk, onClose }: { aircraftId: string, existingSquawk?: any, onClose: () => void }) => {
    const [formValues, setFormValues] = useState({
        "description": null,
        "severity": null,
        ...existingSquawk
    });
    const db = getFirestore()
    const { showOverlay, hideOverlay } = useLoadingOverlay();
    const [showConfirmation, setShowConfirmation] = useState(false)

    const [formErrors, setFormErrors] = useState({
        "description": false,
        "severity": false
    });

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 3,
    };

    const handleChange = (e: any) => {

        const { name, value } = e.target;

        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleChangeCheckbox = (e: any) => {

        const { name, checked } = e.target;
        setFormValues({
            ...formValues,
            [name]: checked,
        });
    };

    const handleChangeNumber = (e: any) => {

        const { name, value } = e.target;

        setFormValues({
            ...formValues,
            [name]: parseFloat(value),
        });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        showOverlay();

        if (existingSquawk) {
            const squawkToEdit = {
                ...formValues,
                pilotId: localStorage.getItem(userKey),
                pilot: localStorage.getItem(userNameKey),
                "date": new Date().toISOString().split("T")[0],
                "status": "Pending"
            }

            await setDoc(doc(db, SquawkCollection, existingSquawk.id), squawkToEdit)
        } else {
            // get id
            const querySnapshot = await getDocs(collection(db, CounterCollection));
            var counterDoc = querySnapshot.docs[0].data();

            //save
            const squawk = {
                ...formValues,
                pilotId: localStorage.getItem(userKey),
                pilot: localStorage.getItem(userNameKey),
                "date": new Date().toISOString().split("T")[0],
                "aircraftId": aircraftId,
                "status": "Pending",
                "squawkNumber": "PS" + counterDoc.squawkId
            }

            await addDoc(collection(db, SquawkCollection), squawk);

            counterDoc = {
                ...counterDoc,
                squawkId: counterDoc.squawkId + 1
            }
            await setDoc(doc(db, CounterCollection, querySnapshot.docs[0].id), counterDoc)
        }
        hideOverlay()
        onClose();
    };

    const cancelSquawk = async () => {
        showOverlay();

        if (existingSquawk) {
            const squawkToEdit = {
                ...formValues,
                pilotId: localStorage.getItem(userKey),
                pilot: localStorage.getItem(userNameKey),
                "date": new Date().toISOString().split("T")[0],
                "status": "Canceled"
            }

            await setDoc(doc(db, SquawkCollection, existingSquawk.id), squawkToEdit)
        }
        hideOverlay()
        onClose();
    };

    return (
        <>
            <Modal
                open={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Card className='signup-card w-[75vw]' sx={style}>
                    <div className='flex  flex-row justify-between'>
                        <h1 className="text-3xl mb-5">Add New SQUAWK</h1>
                        <CloseIcon className='hover:cursor-pointer' onClick={() => onClose()}></CloseIcon>
                    </div>

                    <Box
                        component="form"
                        sx={{ flexGrow: 1 }}
                        onSubmit={handleSubmit}
                        className="mt-4"
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Description"
                                    name="description"
                                    value={formValues.description}
                                    onChange={handleChange}
                                    error={formErrors.description}
                                    className="w-full custom-textarea"
                                    rows={2}
                                    multiline
                                    required
                                />
                            </Grid>

                            <Grid item xs={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="dropdown-label" sx={{ fontSize: '0.7rem' }}>Severity</InputLabel>
                                    <Select
                                        labelId="dropdown-label"
                                        name="severity"
                                        value={formValues.severity}
                                        label="Select Option"
                                        onChange={handleChange}
                                        required
                                        className="h-[50px]"
                                        sx={{ fontSize: '0.8rem' }}
                                    >
                                        {squawkSeverity.map(item => <MenuItem value={item.value} key={item.value}>{item.value}</MenuItem>)}

                                    </Select>
                                </FormControl>
                            </Grid>

                        </Grid>

                        <div className="mt-5 flex gap-2">
                            <CustomButtonFill text='Submit' onClick={() => { }} />
                            {existingSquawk && <CustomButtonNonSubmit text='Delete Squawk'
                                // onClick={() => cancelSquawk()}
                                onClick={() => {
                                    setShowConfirmation(true)
                                }}
                            />}
                        </div>
                    </Box>
                </Card>
            </Modal>

            {
                showConfirmation && <DeleteConfirmModal title="Delete Squawk" onClose={() => { setShowConfirmation(false) }} onDelete={() => {
                    cancelSquawk()
                }} />
            }
        </>)
}