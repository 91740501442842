import { useQuery, useQueryClient } from "@tanstack/react-query";
import { UserModel } from "../models/user";
import { queryKeyPilotsOfUser, userKey } from "../keys";
import { getAllPilots_firebase } from "../services/user";

export const usePilots = () => {
    const queryClient = useQueryClient()
    const userId = localStorage.getItem(userKey)!

    const query = useQuery<UserModel[]>({ queryKey: [queryKeyPilotsOfUser, userId], queryFn: () => getAllPilots_firebase() })

    const invalidateUserPilots = () => {
        queryClient.invalidateQueries({ queryKey: [queryKeyPilotsOfUser, userId] });
    };

    return {
        ...query,
        refreshUserPilots: invalidateUserPilots,
    };
}