
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import ErrorIcon from '@mui/icons-material/Error';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import InfoIcon from '@mui/icons-material/Info';
import { addDate, formatDateToYYYYMMDDToday, getDateDiff } from '../utils/dateutils';
import { cn } from '../userData/userData';

export enum Severity {
    Done,
    Low,
    Medium,
    High,
    Info
}

export const getGenericSeverityDate = (dateDiff: number) => {
    var severity = Severity.Done;
    if (dateDiff > -30 && dateDiff < 0) {
        severity = Severity.Medium
    } else if (dateDiff < -30) {
        severity = Severity.Done
    } else {
        severity = Severity.High
    }

    return severity;
}

export const InfoSeverityIcon = ({ severity, fontSize }: { severity: Severity, fontSize?: number }) => {
    if (severity == Severity.Done) {
        return <CheckCircleIcon style={{ fontSize: fontSize }} className={cn("text-[green] ")} />
    } else if (severity == Severity.Low) {
        return <ArrowDropDownCircleIcon style={{ fontSize: fontSize }} className={cn("text-[#89CFF0] ")} />
    } else if (severity == Severity.Medium) {
        return <AccessTimeFilledIcon style={{ fontSize: fontSize }} className={cn("text-[#FFA500] ")} />
    } else if (severity == Severity.High) {
        return <ErrorIcon style={{ fontSize: fontSize }} className={cn("text-[red] text-[5px]")} />
    } else if (severity == Severity.Info) {
        return <InfoIcon style={{ fontSize: fontSize }} className={cn("text-[#FFA500] ")} />
    }
    return <></>
}

export const InfoNotificationSeverityIcon = ({ severity }: { severity: Severity }) => {
    if (severity == Severity.Done) {
        return <ArrowDropDownCircleIcon className="text-[green]" />
    } else if (severity == Severity.Medium) {
        return <AccessTimeFilledIcon className="text-[#FFA500]" />
    } else if (severity == Severity.High) {
        return <ErrorIcon className="text-[red]" />
    }
    return <></>
}


export const getSeverityColor = (severity: Severity) => {
    if (severity == Severity.Done) {
        return "green"
    } else if (severity == Severity.Low) {
        return "#89CFF0"
    } else if (severity == Severity.Medium) {
        return "#FFA500";
    }
    else if (severity == Severity.High) {
        return "red"
    }
    return "gray"
}

export const getSeverityBgColor = (severity: Severity) => {
    if (severity == Severity.Done) {
        return "bg-[green]"
    } else if (severity == Severity.Low) {
        return "bg-[#89CFF0]"
    } else if (severity == Severity.Medium) {
        return "bg-[#FFA500]";
    }
    else if (severity == Severity.High) {
        return "bg-[red]"
    }
    return "bg-[gray]"
}

export const getSeverityBorderColor = (severity: Severity) => {
    if (severity == Severity.Done) {
        return "border-[green]"
    } else if (severity == Severity.Low) {
        return "border-[#89CFF0]"
    } else if (severity == Severity.Medium) {
        return "border-[#FFA500]";
    }
    else if (severity == Severity.High) {
        return "border-[red]"
    }
    return "border-[gray]"
}

// 25 hours or 30 days
export const getSeverityWithDateAndHours = (notifyInDays: number, notifyInDaysStart: string, notifyInHours: number, notifyStartHours: number, currentTT: number) => {
    let severityDays = Severity.Done
    let hoursSeverity = Severity.Done

    if (notifyInDays > 0) {
        var dateDiff = getDateDiff(formatDateToYYYYMMDDToday(), addDate(notifyInDaysStart, notifyInDays, 0, 0))
        if (dateDiff < 0) {
            severityDays = Severity.High
        } else if (dateDiff < 30) {
            severityDays = Severity.Medium
        }
    }

    if (notifyInHours > 0) {
        if ((notifyStartHours + notifyInHours - currentTT) < 0) {
            hoursSeverity = Severity.High
        } else if ((notifyStartHours + notifyInHours - currentTT) < 25) {
            hoursSeverity = Severity.Medium
        }
    }

    if (severityDays == Severity.High || hoursSeverity == Severity.High) {
        return Severity.High
    } else if (severityDays == Severity.Medium || hoursSeverity == Severity.Medium) {
        return Severity.Medium
    } else {
        return Severity.Done
    }
}


export const getSeverityWithDateAndHoursString = (notifyInDays: number, notifyInDaysStart: string, notifyInHours: number, notifyStartHours: number, currentTT: number) => {
    let severityDays = ""
    let hoursSeverity = ""

    if (notifyInDays > 0) {
        var dateDiff = getDateDiff(formatDateToYYYYMMDDToday(), addDate(notifyInDaysStart, notifyInDays, 0, 0))
        if (dateDiff < 0) {
            severityDays = "Overdue"
        } else {
            severityDays = `Due in ${dateDiff} days`
        }
    }

    if (notifyInHours > 0) {
        if ((notifyStartHours + notifyInHours - currentTT) < 0) {
            hoursSeverity = "Overdue"
        } else {
            hoursSeverity = `Due in ${(notifyStartHours + notifyInHours - currentTT).toFixed(1)} hours`
        }
    }

    if (severityDays == "Overdue" || hoursSeverity == "Overdue") {
        return "Overdue"
    } else if (severityDays == "" || hoursSeverity == "") {
        return severityDays + " " + hoursSeverity
    } else {
        return severityDays + " or " + hoursSeverity
    }
}