import React, { ReactNode } from "react";

interface ChipProps {
  label: string;
  children?: ReactNode;
  className?: string;
}

export const CustomChip: React.FC<ChipProps> = ({ label, className, children }) => {
  return (
    <div
      className={`flex items-center px-2 text-[10px] font-bold text-gray-700 bg-gray-200 rounded-full ${className}`}
    >
      <span className="text-bold">{children} {label}</span>
    </div>
  );
};
