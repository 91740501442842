import { Typography } from "@mui/material";
import { useEffect, useState } from "react";

export const Greeting = ({ name }: { name: string }) => {
    const [greeting, setGreeting] = useState("Good Morning");
    const [emoji, setEmoji] = useState("☀️");

    useEffect(() => {
        const currentHour = new Date().getHours();

        if (currentHour < 12) {
            setGreeting("Good Morning");
            setEmoji("☀️"); // Sun emoji for morning
        } else if (currentHour < 18) {
            setGreeting("Good Afternoon");
            setEmoji("🌤️"); // Partly sunny emoji for afternoon
        } else {
            setGreeting("Good Evening");
            setEmoji("🌙"); // Moon emoji for evening
        }
    }, []);

    return (
        <div className="flex flex-col items-left justify-left rounded p-1 ml-[30px]">
            <h6
                className="inline-block bg-gray-100 text-[#235e8a] px-2 py-1 rounded">
                {greeting} {name} {emoji} ✈️
            </h6>
        </div>
    );
};