import { useMemo, useState } from "react";
import { cn } from "../../../userData/userData";
import Aircraft from '../../../assets/images/Aircraft.jpeg'
import { FirebaseImage } from "../../../components/firebaseImage";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { addDate, formatDateToYYYYMMDDToday, getDateDiff } from "../../../utils/dateutils";
import { SquawkList } from "../squawk/squawkList";
import EditAircraftIcon from '../../../assets/icons/EditAircraftIcon.svg';
import AddMXIcon from '../../../assets/icons/AddMXIcon.svg';
import ViewMXLogbookIcon from '../../../assets/icons/ViewMXLogbookIcon.svg';
import { AdList } from "../ad/adList";
import AddAdIcon from '../../../assets/icons/AddAdIcon.svg';
import { getGenericSeverityDate, getSeverityBgColor, InfoSeverityIcon, Severity } from "../../../components/severity";
import { AircraftModel } from "../../../models/aircraft";
import { useUserAircraftPermissions } from "../../../hooks/usePermissions";
import { CustomChip } from "../../../components/chip";
import SecurityIcon from '@mui/icons-material/Security';
import { NotificationList } from "../notifications/notificationsList";
import { AddAircraft } from "./addAircraft";
import { AddMxLog } from "../mx/addMxLog";

export const AirCraftItem = ({ aircraft, showAllNotifications, getAll }: { aircraft: AircraftModel, showAllNotifications: boolean, getAll: () => void }) => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [squawkSeverity, setSquawkSeverity] = useState(Severity.Done)
    const [notificationSeverity, setNotificationSeverity] = useState(Severity.Done)
    const [adSeverity, setAdSeverity] = useState(Severity.Done)

    const { permissionPower, data: permission } = useUserAircraftPermissions(aircraft?.id, aircraft?.addedUser);

    const calculateOilChangeseverity = () => {
        var severity = Severity.Done
        if (aircraft?.lastOilChangeDate && aircraft?.engineOilChangeDays && aircraft?.engineOilChangeDays != 0) {
            var dateDiff = getDateDiff(aircraft?.lastOilChangeDate, formatDateToYYYYMMDDToday())
            if (aircraft?.engineOilChangeDays < dateDiff) severity = Severity.High
            else if (dateDiff > aircraft?.engineOilChangeDays - 15) severity = Severity.Medium
        }

        var severityHours = Severity.Done
        if (aircraft?.tachTimeAtLastOilChange && aircraft?.engineOilChangeHours && aircraft?.engineOilChangeHours != 0) {
            var tachDiff = aircraft?.currentTach - aircraft?.tachTimeAtLastOilChange
            if (aircraft?.engineOilChangeHours < tachDiff) severityHours = Severity.High
            else if (tachDiff > aircraft?.engineOilChangeHours - 5) severityHours = Severity.Medium
        }
        return (severity == Severity.High || severityHours == Severity.High) ? Severity.High :
            (severity == Severity.Medium || severityHours == Severity.Medium) ? Severity.Medium : Severity.Done
    }

    const calculateOilChangeseverity_engine2 = () => {
        var severity = Severity.Done
        if (aircraft?.lastOilChangeDate_engine2 && aircraft?.engineOilChangeDays_engine2 && aircraft?.engineOilChangeDays_engine2 != 0) {
            var dateDiff = getDateDiff(aircraft?.lastOilChangeDate_engine2, formatDateToYYYYMMDDToday())
            if (aircraft?.engineOilChangeDays_engine2 < dateDiff) severity = Severity.High
            else if (dateDiff > aircraft?.engineOilChangeDays_engine2 - 15) severity = Severity.Medium
        }

        var severityHours = Severity.Done
        if (aircraft?.tachTimeAtLastOilChange_engine2 && aircraft?.engineOilChangeHours_engine2 && aircraft?.engineOilChangeHours_engine2 != 0) {
            var tachDiff = aircraft?.currentTach_engine2 - aircraft?.tachTimeAtLastOilChange_engine2
            if (aircraft?.engineOilChangeHours_engine2 < tachDiff) severityHours = Severity.High
            else if (tachDiff > aircraft?.engineOilChangeHours_engine2 - 5) severityHours = Severity.Medium
        }
        return (severity == Severity.High || severityHours == Severity.High) ? Severity.High :
            (severity == Severity.Medium || severityHours == Severity.Medium) ? Severity.Medium : Severity.Done
    }

    const getSeverityItems = () => {
        const engine1OilChangeSeverity = calculateOilChangeseverity();
        const engine2OilChangeSeverity = calculateOilChangeseverity_engine2();
        const nextLastAnnualDate = addDate(aircraft.lastAnnualDate, 0, 0, 1);
        const dateDiff = getDateDiff(nextLastAnnualDate, formatDateToYYYYMMDDToday())
        const annualSeverity = getGenericSeverityDate(dateDiff);

        const engine1AnnualSeverity = getGenericSeverityDate(getDateDiff(addDate(aircraft.engineLastAnnualDate, 0, 0, 1), formatDateToYYYYMMDDToday()));
        const engine2AnnualSeverity = aircraft.engineMoake_engine2 ? getGenericSeverityDate(getDateDiff(addDate(aircraft.engineLastAnnualDate_engine2, 0, 0, 1), formatDateToYYYYMMDDToday())) : Severity.Done;

        const items = [
            {
                name: "Airframe Annual",
                severity: annualSeverity
            },
            {
                name: "Engine Annual",
                severity: engine1AnnualSeverity
            },
            {
                name: "Engine 2 Annual",
                severity: engine2AnnualSeverity
            },
            {
                name: "AD",
                severity: adSeverity
            },
            {
                name: "Squawk",
                severity: squawkSeverity
            },
            {
                name: "Oil",
                severity: engine1OilChangeSeverity
            },
            {
                name: "Oil",
                severity: engine2OilChangeSeverity
            },
            {
                name: "MX Minder",
                severity: notificationSeverity
            }
        ];

        return items;
    }

    const severityItems = getSeverityItems();
    const highSeverityItems = useMemo(() =>
        severityItems.filter(i => i.severity === Severity.High).map(i => i.name),
        [severityItems]
    );
    const mediumSeverityItems = useMemo(() =>
        severityItems.filter(i => i.severity === Severity.Medium).map(i => i.name),
        [severityItems]
    );

    const getColor = () => {
        if (
            severityItems.some(item => item.severity === Severity.High)) {
            return getSeverityBgColor(Severity.High)
        } else if (
            severityItems.some(item => item.severity === Severity.Medium)) {
            return getSeverityBgColor(Severity.Medium)
        } else {
            return getSeverityBgColor(Severity.Done)
        }
    }

    return <>
        <div className="">
            <div className={cn("p-2 border border-black rounded-[15px] flex cursor-pointer flex-col sm:flex-row ", getColor())} onClick={() => setIsOpen(!isOpen)}>
                <div className="flex">
                    {
                        aircraft.image ? <FirebaseImage img={aircraft.image} classes="w-[80px] h-[51px] ml-0" /> :
                            <img src={Aircraft} alt="Air Loggbooks" className="w-[80px] h-[51px] ml-0 " style={{ fill: 'blue' }} />
                    }

                    <div className="flex flex-col">
                        <span className={cn("font-[500] text-[20px] mt-3 ml-6 text-[#ffffff]")}>{aircraft.aircraftId}</span>
                        <div className="flex flex-col sm:mt-0 ml-6 sm:ml-auto block sm:hidden">
                            {permission?.role && <CustomChip label={permission?.role} className="mr-auto sm:mr-0 sm:ml-auto" ><SecurityIcon style={{ fontSize: 15, paddingBottom: 2 }} className={cn("text-[#235e8a] ")} /></CustomChip>}
                            {highSeverityItems?.length > 0 && <CustomChip label={highSeverityItems.join(", ")} className="mt-[3px] mr-auto" ><InfoSeverityIcon fontSize={15} severity={Severity.High} /></CustomChip>}
                            {mediumSeverityItems?.length > 0 && <CustomChip label={mediumSeverityItems.join(", ")} className="mt-[3px] mr-auto" ><InfoSeverityIcon fontSize={15} severity={Severity.Medium} /></CustomChip>}
                        </div>
                    </div>

                </div>

                <div className="flex flex-col mt-4 sm:mt-0 ml-0 sm:ml-auto hidden sm:block">
                    {permission?.role && <CustomChip label={permission?.role} className="mr-auto sm:mr-0 sm:ml-auto" ><SecurityIcon style={{ fontSize: 15, paddingBottom: 2 }} className={cn("text-[#235e8a] ")} /></CustomChip>}
                    {highSeverityItems?.length > 0 && <CustomChip label={highSeverityItems.join(", ")} className="mt-[3px] mr-auto" ><InfoSeverityIcon fontSize={15} severity={Severity.High} /></CustomChip>}
                    {mediumSeverityItems?.length > 0 && <CustomChip label={mediumSeverityItems.join(", ")} className="mt-[3px] mr-auto" ><InfoSeverityIcon fontSize={15} severity={Severity.Medium} /></CustomChip>}
                </div>
            </div>

            {/* Animated div */}
            <div
                className={`mt-4 transition-all duration-500 transform ${isOpen ? "max-h-[1100px] md:max-h-[900px] opacity-100 scale-100" : "max-h-0 opacity-0 scale-95"
                    } overflow-hidden`}
            >
                <div className="p-4 bg-gray-100 rounded mb-3">
                    {/* <table className="w-[100%]">
                        <tr>
                            <td><span className="text-[#4995CF]">{"Make and Model"}: </span> {aircraft?.make}, {aircraft?.model}</td>
                            <td><span className="text-[#4995CF]">{"Airframe TT"}: </span> {aircraft?.totalTime.toFixed(1)}</td>
                        </tr>
                        <tr>
                            <td><span className="text-[#4995CF]">{"Engine TSMOH"}: </span> {aircraft?.tsmoh.toFixed(1)}<sup>(E1)</sup> {aircraft?.tsmoh_engine2 ? `, ${aircraft?.tsmoh_engine2.toFixed(1)}` : ""}{aircraft?.tsmoh_engine2 ? <sup>(E2)</sup> : null}</td>
                            <td><span className="text-[#4995CF]">{"Tach"}: </span> {aircraft?.currentTach.toFixed(1)}<sup>(E1)</sup> {aircraft?.currentTach_engine2 ? `, ${aircraft?.currentTach_engine2.toFixed(1)}` : ""}{aircraft?.tsmoh_engine2 ? <sup>(E2)</sup> : null}</td>
                        </tr>
                        <tr>
                            <td><span className="text-[#4995CF]">{"Hobbs"}: </span> {aircraft?.hobbs ? aircraft?.hobbs.toFixed(1) : '-'} {aircraft?.hobbs2 ? `, ${aircraft?.hobbs2.toFixed(1)}` : ''}</td>
                            <td><span className="text-[#4995CF]">{"Last Annual"}: </span> {aircraft?.lastAnnualDate}</td>
                        </tr>
                    </table> */}

                    <div className="w-full">
                        <div className="block md:table w-full">
                            {/* Row 1 */}
                            <div className="block md:table-row w-full border-b md:border-none">
                                <div className="block md:table-cell px-4 ">
                                    <span className="text-[#4995CF]">{"Make and Model"}: </span>
                                    <span className="text-[13px]">{aircraft?.make}, {aircraft?.model}</span>
                                </div>
                                <div className="block md:table-cell px-4 ">
                                    <span className="text-[#4995CF]">{"Airframe TT"}: </span>
                                    <span className="text-[13px]">{aircraft?.totalTime.toFixed(1)}</span>
                                </div>
                            </div>
                            {/* Row 2 */}
                            <div className="block md:table-row w-full border-b md:border-none">
                                <div className="block md:table-cell px-4">
                                    <span className="text-[#4995CF]">{"Engine TSMOH"}: </span>
                                    <span className="text-[13px]">{aircraft?.tsmoh.toFixed(1)}<sup>(E1)</sup>
                                        {aircraft?.tsmoh_engine2 ? `, ${aircraft?.tsmoh_engine2.toFixed(1)}` : ""}{aircraft?.tsmoh_engine2 ? <sup>(E2)</sup> : null}</span>
                                </div>
                                <div className="block md:table-cell px-4">
                                    <span className="text-[#4995CF]">{"Tach"}: </span>
                                    <span className="text-[13px]">{aircraft?.currentTach.toFixed(1)}<sup>(E1)</sup>
                                        {aircraft?.currentTach_engine2 ? `, ${aircraft?.currentTach_engine2.toFixed(1)}` : ""}{aircraft?.currentTach_engine2 ? <sup>(E2)</sup> : null}</span>
                                </div>
                            </div>
                            {/* Row 3 */}
                            <div className="block md:table-row w-full">
                                <div className="block md:table-cell px-4">
                                    <span className="text-[#4995CF]">{"Hobbs"}: </span>
                                    <span className="text-[13px]">{aircraft?.hobbs ? aircraft?.hobbs.toFixed(1) : '-'}
                                        {aircraft?.hobbs2 ? `, ${aircraft?.hobbs2.toFixed(1)}` : ''}</span>
                                </div>
                                <div className="block md:table-cell px-4">
                                    <span className="text-[#4995CF]">{"Last Annual"}: </span>
                                    <span className="text-[13px]">{aircraft?.lastAnnualDate}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr className="border-t border-gray-300 my-3 border-dashed" />
                    <span className="text-[14px] font-bold">Engine Oil Change</span>

                    <div className={cn("flex flex-col md:flex-row items-center border-2 p-[2px] rounded-md mb-1 mr-3")}>
                        <div className="flex">
                            <InfoSeverityIcon severity={calculateOilChangeseverity()} />
                            <p className="text-gray-700 text-[12px] ml-2"><b>Engine 1</b></p>
                            <p className="text-gray-700 text-[12px] flex-1 ml-3 hidden md:block"><b>Last Oil changed: {aircraft?.lastOilChangeDate} | Tach: {aircraft?.tachTimeAtLastOilChange}</b></p>
                            <p className="text-gray-700 text-[12px] mx-2"><b>{aircraft?.engineOilChangeHours} hours or {aircraft?.engineOilChangeDays} Days</b></p>
                        </div>
                        <p className="text-gray-700 text-[12px] md:mt-0 md:ml-2 block md:hidden"><b>Last Oil changed: {aircraft?.lastOilChangeDate} | Tach: {aircraft?.tachTimeAtLastOilChange}</b></p>
                    </div>
                    {
                        aircraft?.engineMoake_engine2 &&
                        <div className={cn("flex flex-col md:flex-row items-center border-2 p-[2px] rounded-md mb-1 mr-3")}>
                            <div className="flex">
                                <InfoSeverityIcon severity={calculateOilChangeseverity_engine2()} />
                                <p className="text-gray-700 text-[12px] ml-2"><b>Engine 2</b></p>
                                <p className="text-gray-700 text-[12px] flex-1 ml-3 hidden md:block"><b>Last Oil changed: {aircraft?.lastOilChangeDate_engine2} | Tach: {aircraft?.tachTimeAtLastOilChange_engine2}</b></p>
                                <p className="text-gray-700 text-[12px] mx-2"><b>{aircraft?.engineOilChangeHours_engine2} hours or {aircraft?.engineOilChangeDays_engine2} Days</b></p>
                            </div>
                            <p className="text-gray-700 text-[12px] md:mt-0 md:ml-2 block md:hidden"><b>Last Oil changed: {aircraft?.lastOilChangeDate_engine2} | Tach: {aircraft?.tachTimeAtLastOilChange_engine2}</b></p>
                        </div>
                    }

                    <hr className="border-t border-gray-300 my-3 border-dashed" />

                    <div className="max-h-[150px] overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-400">
                        <SquawkList
                            aircraftId={aircraft.id}
                            permissionPower={permissionPower}
                            onCloseMx={() => {
                                getAll()
                            }} setSquawkSeverity={setSquawkSeverity} />
                    </div>
                    <hr className="border-t border-gray-300 my-3 border-dashed" />

                    <div className="max-h-[250px] overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-400">
                        <AdList
                            aircraftId={aircraft.id}
                            permissionPower={permissionPower}
                            tt={aircraft.totalTime}
                            tsmoh={aircraft.tsmoh}
                            tachTime={aircraft.currentTach}
                            limit={3}
                            showAll={showAllNotifications}
                            onCloseMx={() => {
                                getAll()
                            }} setAdSeverity={setAdSeverity} />
                    </div>
                    <hr className="border-t border-gray-300 my-3 border-dashed" />
                    <div className="max-h-[150px] overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-400">
                        <NotificationList
                            aircraftId={aircraft.id}
                            permissionPower={permissionPower}
                            aircraft={aircraft}
                            showAll={showAllNotifications}
                            onCloseMx={() => {
                                getAll()
                            }} setNotificationSeverity={setNotificationSeverity} />
                    </div>

                    <hr className="border-t border-gray-300 my-3 border-dashed" />
                    {openModal && <AddMxLog onClose={(val: boolean) => {
                        setOpenModal(val)
                        getAll()
                    }} aircraftId={aircraft.id} />}

                    <div className="flex mt-4 gap-3">
                        <Tooltip title="View MX Logbook" arrow>
                            <div className="w-[40px] cursor-pointer" onClick={() => { navigate("/mx-log/" + aircraft.id) }}><img src={ViewMXLogbookIcon} /></div>
                        </Tooltip>
                        {permissionPower >= 4 && <Tooltip title="Add MX Entry" arrow>
                            <div className="w-[40px] cursor-pointer" onClick={() => { setOpenModal(true) }}><img src={AddMXIcon} /></div>
                        </Tooltip>}
                        {permissionPower >= 4 && <Tooltip title="Edit Aircraft" arrow>
                            <div className="w-[40px] cursor-pointer" onClick={() => { setOpenEditModal(true) }}><img src={EditAircraftIcon} /></div>
                        </Tooltip>}
                        <Tooltip title="AD Compliance" arrow>
                            <div className="w-[40px] cursor-pointer" onClick={() => { navigate("/ad-compliances/" + aircraft.id) }}><img src={AddAdIcon} /></div>
                        </Tooltip>
                    </div>

                </div>
            </div>
        </div>

        {openEditModal && <AddAircraft onClose={(val: boolean) => {
            setOpenEditModal(val)
            getAll();
        }} data={aircraft} isEditMode={true} permissionPower={permissionPower} />}

    </>
}