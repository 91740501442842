import { getAuth } from "firebase/auth";

export const addMxSignature = async (mxId: string): Promise<boolean> => {
    const auth = getAuth();
    await auth.currentUser?.getIdToken(/* forceRefresh */ true).then(async (idToken) => {
        await fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_BASE_URL}/AddMxSignature`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${idToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                mxId: mxId
            }),
        });
    });

    return true;
}