import { Box, Card, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Modal, Select, TextField } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";
import { CustomButtonFill } from "../../../components/buttonfill";
import { adCategory, logBookCategory } from "../../../dropdownItems";
import { useLoadingOverlay } from "../../../loading";
import { addDoc, collection, doc, getFirestore, setDoc } from "firebase/firestore";
import { AircraftADCompliancesCollection } from "../../../keys";
import AddLinkIcon from '@mui/icons-material/AddLink';
import AddAlertIcon from '@mui/icons-material/AddAlert';

export const AddEditAd = (prop: { onClose: (val: boolean) => void, data: any, aircraft: any, isEditMode: boolean }) => {
    const { showOverlay, hideOverlay } = useLoadingOverlay();
    const db = getFirestore()
    const [formValues, setFormValues] = useState({
        ...{
            "adfrawdSubject": "",
            "documentNumber": "",
            "documentURL": "",
            "dueDate": new Date().toISOString().split("T")[0],
            "dueDateExplanation": "",
            "effectiveDate": new Date().toISOString().split("T")[0],
            "isRecurring": false,
            "recurringFrequency": "",
            "summary": "",
            "type": "",

            "complianceDate": "",
            "actionTaken": "",
            "maintainerName": "",
            "maintainerLicenseCertificateNumber": "",
            "cw": false,
            "additionalLinks": [],
            "notifyInDaysStart": new Date().toISOString().split("T")[0],
            "notifyInDays": 0,
            "notifyInHours": 0,
            "aircraftTT": 0
        },
        ...prop.data,
        effectiveDate: prop.data ? prop.data?.effectiveDate?.split("T")[0] : new Date().toISOString().split("T")[0]
    })
    const [additionalLinks, setAdditionalLinks] = useState(prop.data?.additionalLinks ?? []);

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 3,
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        showOverlay();
        const adDoc = {
            ...formValues,
            aircraftId: prop.aircraft?.id,
            additionalLinks: additionalLinks
        }

        if (prop.isEditMode) {
            await setDoc(doc(db, AircraftADCompliancesCollection, prop.data.id), adDoc)
        }
        else {
            await addDoc(collection(db, AircraftADCompliancesCollection), adDoc);
        }

        prop.onClose(false)
        hideOverlay()
    }

    const handleChangeCheckbox = (e: any) => {

        const { name, checked } = e.target;
        if (name == "cw" && checked) {
            setFormValues({
                ...formValues,
                [name]: checked,
                "complianceDate": checked ? new Date().toISOString().split("T")[0] : ""
            });
        } else {
            setFormValues({
                ...formValues,
                [name]: checked
            });
        }

    };

    const handleChange = (e: any) => {
        const { name, value } = e.target;

        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleChangeNumber = (e: any) => {

        const { name, value } = e.target;

        setFormValues({
            ...formValues,
            [name]: parseFloat(value),
        });
    };

    return (
        <>
            {<Modal
                open={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Card className='signup-card w-[90vw] ' sx={style}>
                    <div className='flex  flex-row justify-between'>
                        <h1 className="text-3xl">{prop.isEditMode ? "Edit" : "Add"} AD</h1>
                        <CloseIcon className='hover:cursor-pointer' onClick={() => prop.onClose(false)}></CloseIcon>
                    </div>
                    <div className='h-[80vh] overflow-scroll px-3'>
                        <div className='h-[80vh] overflow-scroll px-3'>
                            <Box
                                component="form"
                                sx={{ flexGrow: 1 }}
                                onSubmit={handleSubmit}
                                className="mt-4 pt-4"
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Subject"
                                            name="adfrawdSubject"
                                            value={formValues.adfrawdSubject}
                                            onChange={handleChange}
                                            className="w-full"
                                            required
                                            disabled={prop.isEditMode}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            type="date"
                                            name="effectiveDate"
                                            value={formValues.effectiveDate}
                                            onChange={handleChange}
                                            label="Effective Date"
                                            className="w-full "
                                            required
                                            disabled={prop.isEditMode}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="AD Number"
                                            name="documentNumber"
                                            value={formValues.documentNumber}
                                            onChange={handleChange}
                                            className="w-full"
                                            required
                                            disabled={prop.isEditMode}
                                        />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField
                                            label="Document Url"
                                            name="documentURL"
                                            value={formValues.documentURL}
                                            onChange={handleChange}
                                            className="w-full"
                                            required
                                            disabled={prop.isEditMode}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl fullWidth>
                                            <InputLabel id="dropdown-label" sx={{ fontSize: '0.7rem' }}>Type</InputLabel>
                                            <Select
                                                labelId="dropdown-label"
                                                name="type"
                                                value={formValues.type}
                                                label="Select Option"
                                                onChange={handleChange}
                                                required

                                                className="h-[50px]"
                                                sx={{ fontSize: '0.8rem' }}
                                                disabled={prop.isEditMode}
                                            >
                                                {adCategory.map(l => <MenuItem value={l.value}>{l.value}</MenuItem>)}
                                                {prop.aircraft?.engineMoake_engine2 && <MenuItem value={"Engine 2"}>{"Engine 2"}</MenuItem>}

                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div>
                                            <h6 className="font-bold">Additional Links
                                                <AddLinkIcon className="cursor-pointer ml-3" onClick={() => {
                                                    setAdditionalLinks([...additionalLinks, { title: "", url: "" }])
                                                }} /></h6>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12}>
                                        {additionalLinks.map((l: any, index: number) =>
                                            <Grid container xs={12} spacing={1} className="pt-1" key={index}>
                                                <Grid item xs={3} >
                                                    <TextField
                                                        label={`Link Title`}
                                                        value={additionalLinks[index].title}
                                                        onChange={(e: any) => {
                                                            const updatedLinks = [...additionalLinks];
                                                            updatedLinks[index].title = e.target.value;
                                                            setAdditionalLinks(updatedLinks);
                                                        }}
                                                        className="w-full"
                                                    />
                                                </Grid>
                                                <Grid item xs={9} >
                                                    <TextField
                                                        label={`Link url`}
                                                        value={additionalLinks[index].url}
                                                        onChange={(e: any) => {
                                                            const updatedLinks = [...additionalLinks];
                                                            updatedLinks[index].url = e.target.value;
                                                            setAdditionalLinks(updatedLinks);
                                                        }}
                                                        className="w-full"
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}

                                        <div className="flex-1 border-t border-gray-300 my-3"></div>
                                    </Grid>
                                    <div className="flex-1 border-t border-gray-300"></div>

                                    <Grid item xs={12}>
                                        <TextField
                                            label="Summary"
                                            name="summary"
                                            value={formValues.summary}
                                            onChange={handleChange}
                                            className="w-full custom-textarea"
                                            rows={5}
                                            multiline
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            // type="date"
                                            name="dueDate"
                                            value={formValues.dueDate}
                                            onChange={handleChange}
                                            label="Due Date"
                                            className="w-full "
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField
                                            label="Due Date Explanation"
                                            name="dueDateExplanation"
                                            value={formValues.dueDateExplanation}
                                            onChange={handleChange}
                                            className="w-full"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControlLabel
                                            control={<Checkbox checked={formValues.isRecurring} onChange={handleChangeCheckbox} name="isRecurring" />}
                                            label="Is Recurring"
                                        />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField
                                            label="Recurring Frequency"
                                            name="recurringFrequency"
                                            value={formValues.recurringFrequency}
                                            onChange={handleChange}
                                            className="w-full"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div>
                                            <h6 className="font-bold">Notify Me <AddAlertIcon />
                                                {
                                                    formValues.notifyInDays > 0 && formValues.notifyInHours > 0 ?
                                                        ("(" + formValues.notifyInDays + " Days " + " or " + formValues.notifyInHours + " Hours - Whichever comes first )") :
                                                        formValues.notifyInDays > 0 ? ("(" + formValues.notifyInDays + " Days " + ")") :
                                                            formValues.notifyInHours > 0 ? ("(" + formValues.notifyInHours + " hours )") : ""

                                                }
                                            </h6>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container xs={12} spacing={1} className="pt-1" >
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="In Days"
                                                    name="notifyInDays"
                                                    type="number"
                                                    value={formValues.notifyInDays}
                                                    onChange={handleChangeNumber}
                                                    className="w-full"
                                                />
                                            </Grid>
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="In Hours"
                                                    name="notifyInHours"
                                                    type="number"
                                                    value={formValues.notifyInHours}
                                                    onChange={handleChangeNumber}
                                                    className="w-full"
                                                />
                                            </Grid>
                                        </Grid>
                                        <div className="flex-1 border-t border-gray-300 mt-3"></div>
                                    </Grid>

                                    <Grid item xs={2}>
                                        <FormControlLabel
                                            control={<Checkbox checked={formValues.cw} onChange={handleChangeCheckbox} name="cw" />}
                                            label="Complied with"
                                        />
                                    </Grid>
                                    {formValues.cw && <Grid item xs={2}>
                                        <TextField
                                            type="date"
                                            name="complianceDate"
                                            value={formValues.complianceDate}
                                            onChange={handleChange}
                                            label="Compliance Date"
                                            className="w-full "
                                        />
                                    </Grid>}
                                    {formValues.cw && <Grid item xs={2}>
                                        <TextField
                                            type="number"
                                            name="aircraftTT"
                                            value={formValues.aircraftTT}
                                            onChange={handleChangeNumber}
                                            label="Aircraft TT"
                                            className="w-full "
                                        />
                                    </Grid>}
                                    {formValues.cw && <Grid item xs={3}>
                                        <TextField
                                            name="maintainerName"
                                            value={formValues.maintainerName}
                                            onChange={handleChange}
                                            label="Maintainer Name"
                                            className="w-full "
                                        />
                                    </Grid>}
                                    {formValues.cw && <Grid item xs={3}>
                                        <TextField
                                            name="maintainerLicenseCertificateNumber"
                                            value={formValues.maintainerLicenseCertificateNumber}
                                            onChange={handleChange}
                                            label="Maintainer License Certificate Number"
                                            className="w-full "
                                        />
                                    </Grid>}
                                    {formValues.cw && <Grid item xs={12}>
                                        <TextField
                                            label="Action Taken"
                                            name="actionTaken"
                                            value={formValues.actionTaken}
                                            onChange={handleChange}
                                            className="w-full custom-textarea"
                                            rows={5}
                                            multiline
                                        />
                                    </Grid>}
                                </Grid>

                                <div className="mt-5 flex gap-3">
                                    <CustomButtonFill text='Update' onClick={() => { }} />
                                </div>
                            </Box>
                        </div>
                    </div>

                </Card>
            </Modal >}
        </>
    )
}