import { useQuery, useQueryClient } from "@tanstack/react-query"
import { AircraftModel } from "../models/aircraft"
import { queryKeyAircraftById, queryKeyAircraftsOfOwner, queryKeyAircraftsOfPilot, queryKeyAircraftsOfUser, userKey } from "../keys"
import { getAircraftById_firebase, getAllAircrafts_firebase } from "../services/aircraft"
import { getPermissionPower, useUserPermissions } from "./usePermissions"

export const useAircrafts = () => {
    const queryClient = useQueryClient()
    const userId = localStorage.getItem(userKey)!
    const { data: permissions, isLoading } = useUserPermissions()

    const query = useQuery<AircraftModel[] | null>({
        queryKey: [queryKeyAircraftsOfUser, userId],
        queryFn: () => {
            if (!permissions)
                return Promise.resolve(null);

            return getAllAircrafts_firebase(userId, permissions?.map(p => p.aircraftId))
        },
        enabled: !!permissions,
    })

    const invalidateUserAircrafts = () => {
        queryClient.invalidateQueries({ queryKey: [queryKeyAircraftsOfUser, userId] });
    };

    return {
        ...query,
        refreshUserAircrafts: invalidateUserAircrafts,
        isLoadingPerm: isLoading
    };
}

export const useAircraftsForPilotLog = () => {
    const queryClient = useQueryClient()
    const userId = localStorage.getItem(userKey)!
    const { data: permissions } = useUserPermissions()

    const query = useQuery<AircraftModel[] | null>({
        queryKey: [queryKeyAircraftsOfPilot, userId],
        queryFn: () => {
            if (!permissions)
                return Promise.resolve(null);

            return getAllAircrafts_firebase(userId, permissions?.filter(p => getPermissionPower(p.role) == 3).map(p => p.aircraftId))
        },
        enabled: !!permissions,
    })

    const invalidateUserAircrafts = () => {
        queryClient.invalidateQueries({ queryKey: [queryKeyAircraftsOfPilot, userId] });
    };

    return {
        ...query,
        refreshAircraftForPilot: invalidateUserAircrafts,
    };
}

export const useOwnerAircrafts = () => {
    const queryClient = useQueryClient()
    const userId = localStorage.getItem(userKey)!
    const { data: permissions } = useUserPermissions()

    const query = useQuery<AircraftModel[]>({
        queryKey: [queryKeyAircraftsOfOwner, userId],
        queryFn: () => getAllAircrafts_firebase(userId, permissions?.filter(p => getPermissionPower(p.role) == 5).map(p => p.aircraftId))
    })

    const invalidateUserAircrafts = () => {
        queryClient.invalidateQueries({ queryKey: [queryKeyAircraftsOfOwner, userId] });
    };

    return {
        ...query,
        refreshUserAircrafts: invalidateUserAircrafts,
    };
}

export const useAircraftById = (aircraftId: string) => {
    const queryClient = useQueryClient()

    const query = useQuery<AircraftModel | null>({
        queryKey: [queryKeyAircraftById, aircraftId],
        queryFn: () => getAircraftById_firebase(aircraftId)
    })

    const refreshAircraftById = () => {
        queryClient.invalidateQueries({ queryKey: [queryKeyAircraftById, aircraftId] });
    };

    return {
        ...query,
        refreshAircraftById: refreshAircraftById,
    };
}