import { addDoc, collection, getFirestore } from "firebase/firestore";
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Box, Card, FormControl, Grid, InputLabel, MenuItem, Modal, Select, TextField } from "@mui/material";
import { useLoadingOverlay } from "../../../loading";
import { isUserAvailable_firebase } from "../../../services/user";
import { PermissionsCollection, userKey, userNameKey } from "../../../keys";
import { sendEmail } from "../../../services/email";
import { CustomButtonFill } from "../../../components/buttonfill";

export const AddPermission = ({ aircraftId, otherUsers, onClose }: { aircraftId: string, otherUsers: string[], onClose: () => void }) => {
    const [formValues, setFormValues] = useState({
        "email": "",
        "role": "",
    });
    const db = getFirestore()
    const { showOverlay, hideOverlay } = useLoadingOverlay();
    const [infoMessage, setInfoMessage] = useState<string>()
    const [isError, setIsError] = useState(false)
    const [isUserAvailable, setIsUserAvailable] = useState(false)

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 3,
    };

    const handleChange = (e: any) => {

        const { name, value } = e.target;

        if (name == "email") {
            setInfoMessage("")
            setIsError(false)
        }

        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const checkUser = async (e: any) => {
        const { value } = e.target;

        if (otherUsers.includes(value)) {
            setInfoMessage(`${value} is already added to this aircraft.`)
            setIsError(true)
        }
        else {
            const isUserAvailable = await isUserAvailable_firebase(value)
            setIsUserAvailable(isUserAvailable)
            setIsError(false)
            setInfoMessage(!isUserAvailable ? `${value} is not registered in the Airlogbooks! Invitation email will be sent once permission added.` : "")
        }
    }

    const handleChangeCheckbox = (e: any) => {

        const { name, checked } = e.target;
        setFormValues({
            ...formValues,
            [name]: checked,
        });
    };

    const handleChangeNumber = (e: any) => {

        const { name, value } = e.target;

        setFormValues({
            ...formValues,
            [name]: parseFloat(value),
        });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (!isError) {
            showOverlay();

            const permission = {
                ...formValues,
                addedUserId: localStorage.getItem(userKey),
                addedUser: localStorage.getItem(userNameKey),
                "date": new Date().toISOString().split("T")[0],
                "aircraftId": aircraftId,
                "invited": !isUserAvailable
            }
            await addDoc(collection(db, PermissionsCollection), permission);

            if (!isUserAvailable) {
                await sendEmail(formValues.email, localStorage.getItem(userNameKey)!)
            }

            onClose();
            hideOverlay()
        }
    };

    return (<Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Card className='signup-card w-[700px]' sx={style}>
            <div className='flex  flex-row justify-between'>
                <h1 className="text-3xl mb-5">Add Permission</h1>
                <CloseIcon className='hover:cursor-pointer' onClick={() => onClose()}></CloseIcon>
            </div>

            {infoMessage &&
                <Alert severity={isError ? "error" : "info"}>
                    {infoMessage}
                </Alert>}

            <Box
                component="form"
                sx={{ flexGrow: 1 }}
                onSubmit={handleSubmit}
                className="mt-4"
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Email"
                            type="email"
                            name="email"
                            value={formValues.email}
                            onChange={handleChange}
                            onBlur={checkUser}
                            className="w-full"
                            required
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel id="dropdown-label" sx={{ fontSize: '0.7rem' }}>Role</InputLabel>
                            <Select
                                labelId="dropdown-label"
                                name="role"
                                value={formValues.role}
                                label="Select Option"
                                onChange={handleChange}
                                required
                                className="h-[50px]"
                                sx={{ fontSize: '0.8rem' }}
                            >
                                <MenuItem value={"Admin"}>Admin</MenuItem>
                                <MenuItem value={"Pilot"}>Pilot</MenuItem>
                                <MenuItem value={"Mechanic"}>Mechanic</MenuItem>
                                {/* <MenuItem value={"ViewOnly"}>View Only</MenuItem> */}
                            </Select>
                        </FormControl>
                    </Grid>

                </Grid>

                <div className="mt-5">
                    <CustomButtonFill text='Add' onClick={() => { }} />
                </div>
            </Box>
        </Card>
    </Modal>)
}
