import { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

interface ProtectedRouteProps {
  children: ReactNode;
}

const queryClient = new QueryClient();

const PublicRoute = ({ children }: ProtectedRouteProps) => {

  return <><QueryClientProvider client={queryClient}>{children}</QueryClientProvider></>;
};

export default PublicRoute;
