import { Button, Checkbox, Popover, Skeleton, Tooltip } from "@mui/material"

import { useState } from "react"
import { aircraftShowHide, pilotShowHide, userKey } from "../keys"
import { cn } from "../userData/userData"
import { AirCraftItem } from "./features/aircraft/aircraft"
import { PilotItem } from "./features/pilot/pilot"
import TuneIcon from '@mui/icons-material/Tune';
import AddFlightIcon from '../assets/icons/AddFlightIcon.svg';
import AddAircraftIcon from '../assets/icons/AddAircraftIcon.svg';
import { useUser } from "../hooks/useUser"
import { useAircrafts } from "../hooks/useAircrafts"
import { usePilots } from "../hooks/usePilots"
import { AddAircraft } from "./features/aircraft/addAircraft"
import { AddPilotLog } from "./features/pilot/addPilotLog"
import { Greeting } from "../components/greeting"

export const DashboardInner = ({ isDiv1Visible, isDiv2Visible, showAllNotifications }: { isDiv1Visible: boolean, isDiv2Visible: boolean, showAllNotifications: boolean }) => {
    const [openModal, setOpenModal] = useState(false);
    const [openFlightModal, setOpenFlightModal] = useState(false);

    const [aircraftsShow, setAircraftsShow] = useState<any[]>(() => {
        const stored = localStorage.getItem(aircraftShowHide);
        return stored ? JSON.parse(stored) : [];
    })
    const [pilotsShow, setPilotsShow] = useState<any[]>(() => {
        const stored = localStorage.getItem(pilotShowHide);
        return stored ? JSON.parse(stored) : [];
    })
    const [rerenderMe, setRerenderMe] = useState("me")

    const { data: user, refreshUser } = useUser();

    const { data: aircrafts, isLoading: isLoadingAircrafts, isLoadingPerm, refreshUserAircrafts } = useAircrafts();
    const { data: pilots, isLoading: isLoadingPilots, refreshUserPilots } = usePilots()

    // ########## select hide
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [anchorEl1, setAnchorEl1] = useState<HTMLButtonElement | null>(null);

    const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl1(event.currentTarget);
    };

    const handleClose1 = () => {
        setAnchorEl1(null);
    };

    const open1 = Boolean(anchorEl1);
    const id1 = open1 ? 'simple-popover' : undefined;
    //###############

    return <>

        <div className={cn("grid  gap-[30px] mx-0 md:mx-5", `grid-cols-${isDiv1Visible && isDiv2Visible ? '2' : '1'}`)}>

            {isDiv1Visible && <div className="flex flex-col col-span-2 sm:col-span-2 md:col-span-2 lg:col-span-1 order-2 lg:order-1">
                <div className="flex items-center justify-center mb-3 ">
                    <Tooltip title="Add New Aircraft" arrow>
                        <div className="w-[55px] cursor-pointer" onClick={() => setOpenModal(true)}><img src={AddAircraftIcon} /></div>
                    </Tooltip>
                    <span className="font-[500] text-[25px] text-[#222222] text-center mb-2 ml-2">Select Aircraft
                        <Button aria-describedby={id1} onClick={handleClick1}>
                            <TuneIcon className="ml-3 text-blue-500 cursor-pointer" />
                        </Button>
                        <Popover
                            id={id1}
                            open={open1}
                            anchorEl={anchorEl1}
                            onClose={handleClose1}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <div className="p-3">
                                {aircrafts?.map((a, index) => <p key={index}><Checkbox
                                    edge="start"
                                    checked={aircraftsShow.includes(a.aircraftId)}
                                    tabIndex={-1}
                                    onChange={(e) => {
                                        const ac = e.target.checked ? [...aircraftsShow, a.aircraftId] : aircraftsShow.filter(e => e != a.aircraftId)
                                        setAircraftsShow(ac)
                                        localStorage.setItem(aircraftShowHide, JSON.stringify(ac))
                                    }}
                                /> {a.aircraftId}</p>)}
                            </div>
                        </Popover>
                    </span>
                </div>

                {aircrafts?.map((a, index) => aircraftsShow.includes(a.aircraftId) || aircraftsShow.length == 0 ?
                    <AirCraftItem key={index} showAllNotifications={showAllNotifications} aircraft={a} getAll={refreshUserAircrafts} /> : <div key={index}></div>)}

                {
                    aircrafts && aircrafts.length == 0 && <div className="text-center mt-5 text-[grey]"> - No Aircrafts available. - </div>
                }

                {
                    (isLoadingAircrafts || isLoadingPerm) && <div>
                        <Skeleton variant="rectangular" className="mt-2" height={65} />
                        <Skeleton variant="rectangular" className="mt-2" height={65} />
                        <Skeleton variant="rectangular" className="mt-2" height={65} />
                    </div>
                }

                {/* <div className="pt-3 mb-3">

                </div> */}

                {openModal && <AddAircraft onClose={(val: boolean) => {
                    setOpenModal(val);
                    refreshUserAircrafts();
                }} data={{}} isEditMode={false} permissionPower={0} />}
            </div>}
            {isDiv2Visible && <div className="flex flex-col col-span-2 sm:col-span-2 md:col-span-2 lg:col-span-1 order-1 lg:order-2 mt-3 lg:mt-0">
                <div className="flex items-center justify-center mb-3 ">
                    {user?.pilotInfo.isPilot && <Tooltip title="Add New Flight" arrow>
                        <div className="w-[55px] cursor-pointer" onClick={() => setOpenFlightModal(true)}><img src={AddFlightIcon} /></div>
                    </Tooltip>}
                    <span className="font-[500] text-[25px] text-[#222222] mb-2 text-center ml-2">Select Pilot

                        <Button aria-describedby={id} onClick={handleClick}>
                            <TuneIcon className="ml-3 text-blue-500 cursor-pointer" />
                        </Button>

                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <div className="p-3">
                                {pilots?.map((a, index) => <p key={index}><Checkbox

                                    edge="start"
                                    checked={pilotsShow.includes(a.email)}
                                    tabIndex={-1}
                                    onChange={(e) => {
                                        console.log(a.email, pilotsShow)
                                        const ps = e.target.checked ? [...pilotsShow, a.email] : pilotsShow.filter(e => e != a.email)
                                        setPilotsShow(ps)
                                        localStorage.setItem(pilotShowHide, JSON.stringify(ps))
                                    }}
                                /> {a.firstName} {a.lastName}</p>)}
                            </div>
                        </Popover>
                    </span>
                </div>
                {pilots?.map((a, index) => pilotsShow.includes(a.email) || pilotsShow.length == 0 ? <PilotItem pilot={a} key={index}
                    onChange={() => { refreshUserPilots() }} rerenderKey={a.email == localStorage.getItem(userKey) ? rerenderMe : a.email}
                /> : <div key={index}></div>)}

                {
                    pilots && pilots.length == 0 && <div className="text-center mt-5 text-[grey]"> - No Pilot details available - </div>
                }
                {
                    isLoadingPilots && <div>
                        <Skeleton variant="rectangular" className="mt-2" height={65} />
                        <Skeleton variant="rectangular" className="mt-2" height={65} />
                        <Skeleton variant="rectangular" className="mt-2" height={65} />
                    </div>
                }

                {/* <div className="pt-3 mb-3">
                </div> */}

                {openFlightModal && <AddPilotLog onClose={(val: boolean) => {
                    setOpenFlightModal(val)
                    refreshUserAircrafts()
                    setRerenderMe("me" + Math.random())
                }} openAddAircraft={() => setOpenModal(true)} />}
            </div>}
        </div>

    </>
}
