import { collection, FieldPath, getDocs, getFirestore, query, where, documentId, doc, getDoc } from "firebase/firestore";
import { AircraftCollection } from "../keys";
import { AircraftModel } from "../models/aircraft";

export const getAllAircrafts_firebase = async (userId: string, aircraftIds: string[] | undefined): Promise<AircraftModel[]> => {
    const db = getFirestore()

    let uniqueAircraftIds = Array.from(new Set(aircraftIds))

    const data: AircraftModel[] = [];
    if (uniqueAircraftIds && uniqueAircraftIds?.length > 0) {
        const q = query(collection(db, AircraftCollection), where(documentId(), "in", uniqueAircraftIds));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            data.push({ ...doc.data(), id: doc.id } as AircraftModel);
        });
    }

    if (userId) {
        const q = query(collection(db, AircraftCollection), where("addedUser", "==", userId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            data.push({ ...doc.data(), id: doc.id } as AircraftModel);
        });
    }

    return Array.from(
        new Map(data.map(item => [item.id, item])).values()
    );;
}

export const getAircraftById_firebase = async (aircraftId: string): Promise<AircraftModel | null> => {
    const db = getFirestore()
    const docRef = doc(db, AircraftCollection, aircraftId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return ({ ...docSnap.data(), id: docSnap.id }) as AircraftModel
    }

    return null;
}