import { Card, Modal } from "@mui/material"
import { collection, doc, getDocs, getFirestore, query, setDoc, where } from "firebase/firestore"
import { PilotLogAttachmentsCollection, PilotLogbookCollection } from "../../../keys"
import { ReactNode, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { useLoadingOverlay } from "../../../loading"
import { CustomBack } from "../../../components/backbutton"
import { MaterialReactTable, MRT_ColumnDef, useMaterialReactTable } from "material-react-table"
import { CustomButton, CustomButtonSmall } from "../../../components/button"
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { usePilotLogAttachments } from "../../../hooks/useAttachments"
import { PilotLogAttachmentsModel } from "../../../models/logAttachments"
import CloseIcon from '@mui/icons-material/Close';
import { FirebaseFileDownloader } from "../../../components/fileDownload"
import { MultiFileUploadWidget } from "../../../components/multiFileUpload"

export const PilotLogbooks = () => {
    const [logs, setLogs] = useState<any[]>([]);
    const [loaded, setLoaded] = useState(false)
    const { email } = useParams();
    const [showAttachments, setShowAttachments] = useState(false)

    const { showOverlay, hideOverlay } = useLoadingOverlay();

    useEffect(() => {
        getAllLogs()
    }, [])

    const getAllLogs = async () => {
        showOverlay()
        const db = getFirestore()

        const data: any[] = [];

        const q = query(collection(db, PilotLogbookCollection), where("pilotId", "==", email));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            data.push(doc.data());
        });

        const sortedData = data.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
        setLogs(sortedData);
        setLoaded(true)
        hideOverlay()
    }

    return (<>
        <div>
            {/* <span className="font-[700] text-[28px] text-[#222222]">Pilot Logbook</span> */}
            <Card className="mt-3 pt-3">
                <CustomBack backTo={'/'} />
                <LogbookFilterTable logs={logs} >
                    <span className="font-bold ml-3 text-[18px] mt-3 absolute top-0 z-[10] flex flex-row gap-3">
                        {logs[0]?.pilot}'s pilot logbook
                        <div className="">
                            <CustomButtonSmall text="Pilot Log Attachments" onClick={() => { setShowAttachments(true) }} ><AttachFileIcon /></CustomButtonSmall>
                        </div>
                    </span>
                </LogbookFilterTable>

                {
                    showAttachments && <PilotLogAttachments pilotId={logs[0]?.pilotId} onClose={() => {
                        setShowAttachments(false)
                    }} />
                }
            </Card>
        </div>
    </>)
}

const styleProps = {
    muiTableHeadCellProps: {
        sx: {
            backgroundColor: '#3A8CC9',
            color: 'white',
        },
    },
}

const LogbookFilterTable = ({ logs, children }: {
    logs: any[],
    children: ReactNode
}) => {
    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            { accessorKey: "date", header: "Date", ...styleProps },
            { accessorKey: "tachStart", header: "Tach Start", size: 100, ...styleProps },
            { accessorKey: "tachEnd", header: "Tach End", size: 100, ...styleProps },
            { accessorKey: "hobbsStart", header: "Hobbs Start", size: 100, ...styleProps },
            { accessorKey: "hobbsEnd", header: "Hobbs End", size: 100, ...styleProps },
            { accessorKey: "aircraftId", header: "Aircraft Id", size: 120, ...styleProps },
            { accessorKey: "pilot", header: "Pilot", size: 120, ...styleProps },
            { accessorKey: "make&Model", header: "Aircraft Make and Model", size: 250, ...styleProps },
            { accessorKey: "year", header: "Aircraft Year", size: 120, ...styleProps },
            { accessorKey: "departureAirport", header: "Departure Airport", size: 150, ...styleProps },
            { accessorKey: "arrivalAirport", header: "Arrival Airport", size: 150, ...styleProps },
            { accessorKey: "aircraftCategory", header: "Aircraft Category", size: 150, ...styleProps },
            { accessorKey: "totalTime", header: "Total Time", size: 100, ...styleProps },
            { accessorKey: "dayLanding", header: "Day Landing(s)", size: 150, ...styleProps },
            { accessorKey: "nightLanding", header: "Night Landing(s)", size: 150, ...styleProps },
            { accessorKey: "pic", header: "PIC", size: 100, ...styleProps },
            { accessorKey: "sic", header: "SIC", size: 100, ...styleProps },
            { accessorKey: "crossCountry", header: "Cross Country", size: 150, ...styleProps },
            { accessorKey: "actualInst", header: "Actual Inst.", size: 150, ...styleProps },
            { accessorKey: "simulatedInst", header: "Simulated Inst.", size: 150, ...styleProps },
            { accessorKey: "night", header: "Night", size: 100, ...styleProps },
            { accessorKey: "instructor", header: "Instructor", size: 150, ...styleProps },
            { accessorKey: "dualReceived", header: "Dual Received", size: 150, ...styleProps },
            { accessorKey: "solo", header: "Solo", size: 100, ...styleProps },
            { accessorKey: "remarks", header: "Remarks, Procedures, Maneuvers", size: 350, ...styleProps },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data: logs,
        initialState: { showColumnFilters: true, density: "compact", },
    });

    return <div className="relative">
        <MaterialReactTable table={table} />
        {children}
    </div>;
};

export const PilotLogAttachments = ({ pilotId, onClose }: { pilotId: string, onClose: () => void }) => {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 3,
    };

    const { data: pilotLogsAttachmentDoc, refreshPilotLogAttachments } = usePilotLogAttachments(pilotId);

    const handleMultifileUpload = async (e: any) => {
        const db = getFirestore();
        const { name, value } = e;

        var attachments: PilotLogAttachmentsModel = {
            pilotId: pilotId,
            attachments: [...pilotLogsAttachmentDoc?.attachments ?? [], ...value]
        }

        await setDoc(doc(db, PilotLogAttachmentsCollection, pilotId), attachments)
        refreshPilotLogAttachments();
    }

    return <>
        <Modal
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Card className='signup-card w-[1000px] min-h-[300px]' sx={style}>
                <div className='flex  flex-row justify-between'>
                    <h1 className="text-3xl mb-5">Pilot Log Vault</h1>
                    <CloseIcon className='hover:cursor-pointer' onClick={() => onClose()}></CloseIcon>
                </div>
                <div className="overflow-scroll max-h-[70vh]">
                    <div className="mb-3">
                        {pilotLogsAttachmentDoc?.attachments?.map((l: any, index: number) => (
                            <FirebaseFileDownloader filePath={l} key={index} />
                        ))}
                    </div>
                    <div className="">
                        Upload any desired Pilot logbook files (pdf, images) to keep safe in the Airlogbooks cloud.
                    </div>

                    <MultiFileUploadWidget
                        id="pilot-log files"
                        label={"Upload Pilot Log files"}
                        folderName={`PilotLogDocuments`}
                        // value={formValues.image}
                        onChange={(e: string[]) => handleMultifileUpload({ name: "attachments", value: e })}
                    />
                </div>

                <div className="mt-5 ml-auto">
                    <CustomButton text='OK' onClick={() => { onClose() }} />
                </div>
            </Card>
        </Modal>
    </>
}