import { Alert, Card, Link } from '@mui/material';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import './auth.css'

import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useState } from 'react';
import Logo from '../assets/images/logo.jpeg';
import { CustomButtonFill } from '../components/buttonfill';
import { useLoadingOverlay } from '../loading';

export const ForgotPassword = () => {
    const [error, setError] = useState<string | null>(null);
    const [message, setMessage] = useState<string | null>(null);
    const auth = getAuth();
    const { showOverlay, hideOverlay } = useLoadingOverlay();

    const uiSchema = {
        'ui:submitButtonOptions': {
            submitText: 'Forgot Password',
        },
        "email": {
            "ui:placeholder": "Enter your email"
        },
    };

    const schema: RJSFSchema = {
        title: 'AirLogbooks Password reset',
        type: 'object',
        properties: {
            email: {
                type: 'string',
                "format": "email"
            },
        },
        "required": ["email"]
    };

    const handleResetPassword = async (email: string) => {
        showOverlay()
        try {
            await sendPasswordResetEmail(auth, email);
            setError("");
            setMessage("Password reset email sent. Please check your inbox.");
            hideOverlay()
        } catch (err: any) {
            setError(err.message);
            setMessage("")
            hideOverlay()
        }
    };


    return (
        <div className="signup-form">
            <Card className='signup-card w-[500px]'>
                <img src={Logo} alt="Air Loggbooks" className="w-[100px] mt-[17px] m-auto" />
                {error && <Alert severity="error">{error}</Alert>}
                {message && <Alert severity="info">{message}</Alert>}
                <Form schema={schema} validator={validator} uiSchema={uiSchema}
                    onSubmit={(data) => {
                        handleResetPassword(data.formData.email)
                    }} >
                    <div className='mt-3 mb-3'>
                        <CustomButtonFill text='Reset Password' onClick={() => { }} />
                    </div>
                </Form>
                <Link href="/signin" className='mt-10'>SignIn</Link>
            </Card>
        </div>
    );
};

// Reset your password for %APP_NAME%
// <p>Hello,</p>
// <p>Follow this link to reset your %APP_NAME% password for your %EMAIL% account.</p>
// <p><a href='%LINK%'>%LINK%</a></p>
// <p>If you didn’t ask to reset your password, you can ignore this email.</p>
// <p>Thanks,</p>
// <p>Your %APP_NAME% team</p>


{/* <div style="font-family: Arial, sans-serif; background-color: #f9f9f9; margin: 0; padding: 20px;">
    <div style="max-width: 600px; margin: auto; background: #ffffff; padding: 20px; border: 1px solid #dddddd; border-radius: 8px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);">
        <p style="font-size: 16px; color: #555555; line-height: 1.5;">Hi,</p>
        <p style="font-size: 16px; color: #555555; line-height: 1.5;">Follow this link to reset your AirLogBook password for your %EMAIL% account.</p>
        <a href='%LINK%' style="display: inline-block; padding: 10px 20px; background-color: #007BFF; color: #ffffff; text-decoration: none; border-radius: 5px;">Reset Password</a>
        <p style="font-size: 16px; color: #555555; line-height: 1.5;">If button is not working, please use following link to reset your password.</p>
        <p><a href='%LINK%'>%LINK%</a></p>
        <p style="font-size: 16px; color: #555555; line-height: 1.5;">If you didn’t ask to reset your password, you can ignore this email.</p >
        <p style="font-size: 16px; color: #555555; line-height: 1.5;">Thanks!</p >
        <p style="font-size: 16px; color: #555555; line-height: 1.5;">AirLogbooks</p>
    </div>
</div> */}

{/* <div style="font-family: Arial, sans-serif; background-color: #f9f9f9; margin: 0; padding: 20px;">
    <div style="max-width: 600px; margin: auto; background: #ffffff; padding: 20px; border: 1px solid #dddddd; border-radius: 8px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);">
        <p style="font-size: 16px; color: #555555; line-height: 1.5;">Hi,</p>
        <p style="font-size: 16px; color: #555555; line-height: 1.5;">Follow this link to reset your AirLogBook password for your %EMAIL% account.</p>
        <p><a href='%LINK%'>%LINK%</a></p>
        <p style="font-size: 16px; color: #555555; line-height: 1.5;">If you didn’t ask to reset your password, you can ignore this email.</p >
        <p style="font-size: 16px; color: #555555; line-height: 1.5;">Thanks!</p >
        <p style="font-size: 16px; color: #555555; line-height: 1.5;">AirLogbooks</p>
    </div>
</div> */}