import { Card, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { useLoadingOverlay } from "../../../loading";

export const AdSummary = ({ log, onClose }: { log: any, onClose: (val: boolean) => void }) => {
    const [data, setData] = useState<any>(null);
    const [data2, setData2] = useState<any>(null);
    const [error, setError] = useState(null);
    const { showOverlay, hideOverlay } = useLoadingOverlay();

    // const fetchData = async () => {
    //     showOverlay()
    //     try {
    //         const response = await fetch('https://api.openai.com/v1/chat/completions', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'authorization': `Bearer ${process.env.REACT_APP_GPT_API_KEY}`
    //             },
    //             body: JSON.stringify(
    //                 {
    //                     "model": "gpt-4o",
    //                     "messages": [
    //                         { "role": "user", "content": `Summarize this aircraft AD. In point form if possible. This will directly show in the application. without using phrases like 'certainly' or 'sure': ${log?.adfrawdSubject} ${log?.adfrawdSubject}` }
    //                     ]
    //                 }
    //             ),
    //         });

    //         if (!response.ok) {
    //             throw new Error('Network response was not ok');
    //         }

    //         const jsonData = await response.json();
    //         setData(jsonData);
    //     } catch (error: any) {
    //         setError(error.message);
    //     } finally {
    //         hideOverlay()
    //     }
    // };


    // useEffect(() => {
    //     fetchData();
    //     fetchData2();
    // }, []);

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 3,
    };

    return <>
        <Modal
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Card className='signup-card w-[1000px] min-h-[300px]' sx={style}>
                <div className='flex  flex-row justify-between'>
                    <h1 className="text-3xl mb-5">AD Summary (AI generated)</h1>
                    <CloseIcon className='hover:cursor-pointer' onClick={() => onClose(false)}></CloseIcon>
                </div>
                {/* <h2 className="pl-3"><b>{log?.adfrawdSubject}</b></h2> */}
                {/* <div className="whitespace-pre-wrap text-gray-800 p-4">{data?.choices[0]?.message?.content}</div> */}
                <div className="overflow-scroll max-h-[70vh]">
                    <div className="whitespace-pre-wrap">
                        {log.summary ?? "Oops, Something went wrong when generating summary!"}
                    </div>
                    {/* <h4><b>Option 1</b></h4>
                    <TypewriterEffect text={data?.choices[0]?.message?.content ?? ""} />

                    <h4><b>Option 2</b></h4>
                    <TypewriterEffect text={data2?.choices[0]?.message?.content ?? ""} /> */}
                </div>

            </Card>
        </Modal>
    </>
}

export const AdDueDateSummary = ({ log, onClose }: { log: any, onClose: (val: boolean) => void }) => {
    const [data, setData] = useState<any>(null);
    const [data2, setData2] = useState<any>(null);
    const [error, setError] = useState(null);
    const { showOverlay, hideOverlay } = useLoadingOverlay();

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 3,
    };

    return <>
        <Modal
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Card className='signup-card w-[1000px] min-h-[300px]' sx={style}>
                <div className='flex  flex-row justify-between'>
                    <h1 className="text-3xl mb-5">Due Date Explanation (AI generated)</h1>
                    <CloseIcon className='hover:cursor-pointer' onClick={() => onClose(false)}></CloseIcon>
                </div>
                <div className="overflow-scroll max-h-[70vh]">
                    <div className="whitespace-pre-wrap">
                        {log.dueDateExplanation ?? "Oops, Something went wrong"}
                    </div>
                </div>

            </Card>
        </Modal>
    </>
}


const TypewriterEffect = ({ text }: { text: string }) => {
    const [displayedText, setDisplayedText] = useState("");
    const [index, setIndex] = useState(0);
    const fullText = text?.replace(/\*/g, "")

    useEffect(() => {
        if (index < fullText.length) {
            const timeout = setTimeout(() => {
                setDisplayedText((prev) => prev + fullText[index]);
                setIndex((prev) => prev + 1);
            }, 10); // Adjust speed by changing the delay in milliseconds
            return () => clearTimeout(timeout);
        }
    }, [index, fullText]);

    return (
        <div className="whitespace-pre-wrap text-gray-800 p-4 ">
            {displayedText}
        </div>
    );
};

// Let's start with the fields...
// - AD Number (linked like you have them)
// - Effective Date
// - Subject (click on subject get AI generated summary)
// - Due Date (AI)
// - Compliance Date (This would be the date the maintainer complied with the AD)
// - Action Taken (This would be the Add MX Entry description)
// - Recurring (AI) Y/N
// - Due Date (AI) - feeds the dashboard
// - Maintainers Name (from the ADD MX)
// - Maintainers license certificate # (From the ADD MX)
