import { Card } from "@mui/material";
import { useState } from "react";
import { UserDataFormNew } from "./userDataFormNew";

export const UserData = () => {
    const [selected, setSelected] = useState("Maintainer");

    return (
        <div className="flex justify-center mt-[5%]">
            <Card className='signup-card w-[800px] p-3'>
                <UserDataFormNew />
            {/* <h1 className="text-xl mb-5">You are a...</h1> */}
                {/* <div className="grid grid-cols-2 gap-5">
                    <Card >
                        <CardActionArea onClick={() => setSelected("Maintainer")}>
                            <div className={cn('p-3', selected == 'Maintainer' ? 'bg-sky-300' : '')}>Maintainer</div>
                        </CardActionArea>
                    </Card>
                    <Card >
                        <CardActionArea  onClick={() => setSelected("Pilot")}>
                            <div className={cn('p-3', selected == 'Pilot' ? 'bg-sky-300' : '')}>Pilot</div>
                        </CardActionArea>
                    </Card>
                </div>

                {
                    selected == 'Maintainer' ? <UserDataMaintainer /> : <UserDataPilot />
                } */}
            </Card>
        </div>
    );
};

export const cn = (...classNames: (string | undefined | null | false)[]): string => {
    const uniqueClassNames = new Set<string>();

    for (const name of classNames) {
        if (name) {
            name.split(' ').forEach((className) => uniqueClassNames.add(className));
        }
    }

    return Array.from(uniqueClassNames).join(' ');
}