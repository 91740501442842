import { useState } from "react";
import * as XLSX from "xlsx";

export const ExcelUploader = ({ onExtractData, label }: { label: string, onExtractData: (data: any) => void }) => {
    const [isDragOver, setIsDragOver] = useState(false);

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target?.result as ArrayBuffer);
            const workbook = XLSX.read(data, { type: "array" });

            // Assuming the first sheet is the target
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            // Convert sheet to JSON, starting from row 3
            const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            // Extract header from row 3 and data from row 4 onwards
            const headerRowIndex = 2; // Zero-based index for row 3
            const dataStartRowIndex = 3; // Zero-based index for row 4

            // const headers = rows[headerRowIndex] as string[];
            const headers = (rows[headerRowIndex] as string[]).map((header) =>
                header.trim().replace(/\s+/g, "_").toLowerCase()
              );
            const dataRows = rows.slice(dataStartRowIndex);

            // Filter out empty rows
            const filteredRows = dataRows.filter(
                (row: any) => row.some((cell: any) => cell !== undefined && cell !== null && cell !== "")
            );

            // Convert numeric dates to strings and map rows to JSON objects
            const json = filteredRows.map((row: any) =>
                headers.reduce((acc, header, index) => {
                    let value = row[index];

                    // Convert Excel serial date to ISO string if it's a number
                    if (typeof value === "number" && header.toLowerCase() === "date") {
                        value = new Date(Date.UTC(1900, 0, value - 1)).toISOString().split("T")[0];
                    }

                    acc[header.toLowerCase()] = value || ""; // Use lowercase keys
                    return acc;
                }, {} as Record<string, any>)
            );

            onExtractData(json);
        };

        reader.readAsArrayBuffer(file);
    };

    const handleDragEnter = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragOver(true);
    };

    const handleDragLeave = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragOver(false);
    };

    const handleDragOver = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDrop = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragOver(false);
        handleFileUpload(event);
    };

    return (
        <div
            className={`flex flex-col items-center p-4 border-2 border-dashed rounded-md cursor-pointer ${isDragOver ? 'border-blue-500 bg-blue-50' : 'border-gray-300'}`}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            <div className="flex">
                <div>
                    <input
                        type="file"
                        onChange={handleFileUpload}
                        accept=".xlsx, .xls"
                        className="hidden"
                        id={"upload-file"}
                    />

                    <label htmlFor={"upload-file"} className="w-full text-center cursor-pointer">
                        <p className="text-gray-600">Drag & drop file here, or click to select</p>
                        <div
                            className="mt-2 py-2 bg-[#4995CF] text-white rounded-md transition w-[200px] mx-auto"
                        >
                            {label}
                        </div>
                    </label>
                </div>
            </div>
        </div>
    );
};