import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import { PermissionModel } from "../models/permissionModel";
import { PermissionsCollection } from "../keys";

export const getAllPermissions_firebase = async (aircraftId: string): Promise<PermissionModel[]> => {
    const db = getFirestore()

    const data: PermissionModel[] = [];
    if (aircraftId) {
        const q = query(collection(db, PermissionsCollection), where("aircraftId", "==", aircraftId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            data.push({ ...doc.data(), id: doc.id } as PermissionModel);
        });
    }

    return data;
}

export const getUserPermissions_firebase = async (email: string | undefined): Promise<PermissionModel[]> => {
    const db = getFirestore()

    const data: PermissionModel[] = [];
    if (email) {
        const q = query(collection(db, PermissionsCollection), where("email", "==", email));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            data.push({ ...doc.data(), id: doc.id } as PermissionModel);
        });
    }

    return data;
}

export const getUserAircraftPermissions_firebase = async (email: string | undefined, aircraftId: string | undefined): Promise<PermissionModel | null> => {
    const db = getFirestore()

    const data: PermissionModel[] = [];
    if (email && aircraftId) {
        const q = query(collection(db, PermissionsCollection), where("email", "==", email), where("aircraftId", "==", aircraftId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            data.push({ ...doc.data(), id: doc.id } as PermissionModel);
        });
    }

    return data.length > 0 ? data[0] : null;
}