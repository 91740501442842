import { Card, Skeleton } from "@mui/material"
import Logo from '../assets/images/logo.jpeg';
import { Route, Routes, useParams } from "react-router-dom";
import { useAircraftById } from "../hooks/useAircrafts";
import { PublicAircraft } from "./Aircraft";
import { MxLogbooks } from "../Dashboard/features/mx/mxLogbook";
import { AdCompliances } from "../Dashboard/features/ad/adCompliances";


export const PublicView = () => {
    const { aircraftId } = useParams();
    const { data: aircraft, isFetching, isLoading } = useAircraftById(aircraftId ?? "")

    return <Card className="m-5 p-5 min-h-[96vh] w-[100vw]">
        <div className="flex">
            <img src={Logo} alt="Air Loggbooks" className="w-[150px] cursor-pointer" />
            <div className="flex flex-col">
                <div className="flex flex-row justify-between w-[85vw]">
                    <div className="flex flex-col mt-[-15px]">
                        <span className="text-[2.5rem] text-[#235e8a] ml-[30px] p-[0px]">Fly Smart - Maintain Easy - Comply with Confidence</span>
                    </div>
                    <div className="flex float-right">

                        <div>
                        </div>
                    </div>
                </div>
                <div className="border border-[1px] ml-[30px] border-[#235e8a]"></div>
            </div>
        </div>

        <div className="w-[100%] p-3">
            <Routes>
                <Route path="/" element={<div>
                    {aircraft && <PublicAircraft aircraft={aircraft} />}
                    {(isLoading || isFetching) && <div>
                        <Skeleton variant="rectangular" className="mt-2" height={65} />
                        <Skeleton variant="rectangular" className="mt-2" height={500} />
                    </div>}
                </div>} />
                <Route path="/mx-log" element={<MxLogbooks backTo={`/view-only/${aircraft?.id}`} />} />
                <Route path="/ad-compliances" element={<AdCompliances backTo={`/view-only/${aircraft?.id}`} />} />
            </Routes>
        </div>
    </Card>
}