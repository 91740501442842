import { Card, Grid, Modal, Tooltip } from "@mui/material"
import PersonIcon from '@mui/icons-material/Person';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useState } from "react"
import CloseIcon from '@mui/icons-material/Close';
import { AddPermission } from "./addPermission"
import { deleteDoc, doc, getFirestore } from "firebase/firestore"
import { useOwnerAircrafts } from "../../../hooks/useAircrafts";
import { CustomBack } from "../../../components/backbutton";
import { AircraftModel } from "../../../models/aircraft";
import { useAircraftPermissions } from "../../../hooks/usePermissions";
import { PermissionModel } from "../../../models/permissionModel";
import { PublicAccessLink } from "../../../components/publicLink";
import { cn } from "../../../userData/userData";
import { useLoadingOverlay } from "../../../loading";
import { CustomButtonFill } from "../../../components/buttonfill";
import { PermissionsCollection } from "../../../keys";
import { CustomButton } from "../../../components/button";

export const PermissionsManagement = () => {
    const { data: aircrafts, refreshUserAircrafts } = useOwnerAircrafts()

    return <div className="pr-3">
        <Card className="mt-3 pt-3 pl-3 min-h-[75vh]">
            <CustomBack />
            <span className="font-bold mt-3 text-[18px]">Manage permissions</span>

            <div className="my-3">
                <Grid container spacing={2}>
                    {aircrafts?.map((a, i) => <Grid item xs={6} key={i}><AircraftPermissionDetails aircraft={a} /></Grid>)}
                </Grid>
            </div>
        </Card>
    </div>
}

const AircraftPermissionDetails = ({ aircraft }: { aircraft: AircraftModel, }) => {
    const { data: permissions, refreshAircraftPermissions } = useAircraftPermissions(aircraft.id)
    const [selectedPermission, setSelectedPermission] = useState<PermissionModel>()
    const [showDelete, setShowDelete] = useState(false)
    const [showAddModel, setShowAddModel] = useState(false)

    return <div className="border rounded p-3">
        <h3 className="font-bold">Aircraft: {aircraft.aircraftId}</h3>

        <p className='font-bold my-3'>
            <PublicAccessLink url={`https://airlogbooks-87993.web.app/view-only/${aircraft.id}`} text="Public view-only access link" />
        </p>

        <p className='font-bold my-3' onClick={() => {
            setShowAddModel(true)
        }}>
            Permissions <Tooltip title="Add permission" arrow><PersonAddIcon className="ml-2 cursor-pointer" /></Tooltip>
        </p>
        <div className={cn("flex items-center border-2 p-[2px] rounded-md mb-1", "border-gray")}>
            <PersonIcon />
            <p className="text-gray-700 text-[12px] ml-3"><b>{aircraft.addedUser}</b></p>
            <p className="text-gray-700 text-[12px] mr-2 ml-auto"><b>Owner</b></p>
        </div>

        {
            permissions?.map((p, i) => <div key={i} className={cn("flex items-center border-2 p-[2px] rounded-md mb-1", "border-gray")}>
                <PersonIcon />
                <p className="text-gray-700 text-[12px] ml-3"><b>{p.email}</b></p>
                <div className=" ml-auto flex">
                    <p className="text-gray-700 text-[12px] mr-3 pt-1"><b>{p.role}</b></p>
                    <p className="text-gray-700 text-[12px] mr-2" onClick={() => {
                        setSelectedPermission(p)
                        setShowDelete(true)
                    }}>
                        <Tooltip title="Remove permission" arrow><CloseIcon className="cursor-pointer" /></Tooltip>
                    </p>
                </div>
            </div>)
        }

        {showAddModel && <AddPermission aircraftId={aircraft.id}
            otherUsers={[aircraft.addedUser, ...permissions?.map(p => p.email) ?? []]}
            onClose={() => {
                refreshAircraftPermissions()
                setShowAddModel(false)
            }} />}

        {showDelete && <DeletePermission id={selectedPermission?.id ?? ""} onClose={() => {
            refreshAircraftPermissions()
            setShowDelete(false)
        }} />}
    </div>
}

const DeletePermission = ({ id, onClose }: { id: string, onClose: () => void }) => {
    const { showOverlay, hideOverlay } = useLoadingOverlay();
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 3,
    };

    return <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Card className='' sx={style}>
            <div className='flex  flex-row justify-between'>
                <h1 className="text-3xl mb-5">Remove Permission</h1>
                <CloseIcon className='hover:cursor-pointer' onClick={() => onClose()}></CloseIcon>
            </div>
            <h3>
                This can not be undone. You will lose all data! Are you sure?
            </h3>

            <div className="flex mt-5 gap-3">
                <CustomButtonFill text='Yes' onClick={async () => {
                    showOverlay()
                    const db = getFirestore()
                    await deleteDoc(doc(db, PermissionsCollection, id));
                    onClose()
                    hideOverlay()
                }} />
                <CustomButton text="No" onClick={() => onClose()} />
            </div>

        </Card>
    </Modal>
}