import { Card, CardHeader } from "@mui/material";
import Form from "@rjsf/mui";
import { ObjectFieldTemplateProps, RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { doc, setDoc, getFirestore } from "firebase/firestore";
import { licenseType, medClass } from "../dropdownItems";
import { userKey, UsersCollection } from "../keys";
import { useNavigate } from "react-router-dom";
import { FileUploadWidget } from "../components/fileupload";
import { CustomButtonFill } from "../components/buttonfill";
import { useLoadingOverlay } from "../loading";

export const UserDataFormNew = () => {
    const db = getFirestore();
    const navigate = useNavigate();
    const { showOverlay, hideOverlay } = useLoadingOverlay();

    const schema: RJSFSchema = {
        title: 'User Details',
        type: 'object',
        properties: {
            firstName: {
                type: 'string',
                title: "First Name",
            },
            middleName: {
                type: 'string',
                title: "Middle Name",
            },
            lastName: {
                type: 'string',
                title: "Last Name",
            },
            fullLegalName: {
                type: 'string',
                title: "Full Legal Name",
            },
            image: { type: "string", title: "Upload Profile Picture" },
            pilotInfo: {
                "type": "object",
                "title": "",
                "properties": {
                    isPilot: {
                        type: "boolean",
                        title: "Are you a pilot?",
                        default: false
                    },
                },
                "required": [],
                "dependencies": {
                    "isPilot": {
                        "oneOf": [
                            {
                                "properties": {
                                    "isPilot": {
                                        "const": false
                                    }
                                }
                            },
                            {
                                "properties": {
                                    "isPilot": {
                                        "const": true
                                    },
                                    dob: { // pilot begin
                                        type: "string",
                                        format: "date",
                                        title: "Date of Birth",
                                    },
                                    age: {
                                        type: 'number',
                                        title: "Age",
                                    },
                                    licenseType: {
                                        type: 'string',
                                        title: "License Type",
                                        enum: licenseType.map(item => item.value)
                                    },
                                    fAACertificate: {
                                        type: 'number',
                                        title: "FAA Certificate",
                                    },
                                    biennialFlightReviewDate: {
                                        type: "string",
                                        format: "date",
                                        title: "Biennial Flight Review Date",
                                    },
                                    fAAMedicalClass: {
                                        type: 'string',
                                        title: "FAA Medical Class",
                                        enum: medClass.map(item => item.value)
                                    },
                                    fAAMedicalDate: { // pilot end
                                        type: "string",
                                        format: "date",
                                        title: "FAA Medical Date",
                                    },
                                },
                                "required": ["dob", "age", "licenseType", "fAACertificate", "biennialFlightReviewDate", "fAAMedicalClass", "fAAMedicalDate"]
                            }
                        ]
                    }
                }
            },
            maintainerInfo: {
                "type": "object",
                "title": "",
                "properties": {
                    isMaintainer: {
                        type: "boolean",
                        title: "Are you a maintainer?",
                        default: false
                    },
                },
                "required": [],
                "dependencies": {
                    "isMaintainer": {
                        "oneOf": [
                            {
                                "properties": {
                                    "isMaintainer": {
                                        "const": false
                                    }
                                }
                            },
                            {
                                "properties": {
                                    "isMaintainer": {
                                        "const": true
                                    },
                                    certificate: { // maintainer begin
                                        type: 'string',
                                        title: "Certificate",
                                    },
                                    "aAndP": {
                                        type: "boolean",
                                        title: "A&P",
                                        default: false
                                    },
                                    "iA": {
                                        type: "boolean",
                                        title: "IA",
                                        default: false
                                    },
                                    // "owner": { // maintainer end
                                    //     type: "boolean",
                                    //     title: "Owner",
                                    //     default: false
                                    // },
                                    companyLogo: { type: "string", title: "Upload Company Logo" },
                                    companyName: { // company
                                        type: 'string',
                                        title: "Company Name",
                                    },
                                    companyAddress: { // company
                                        type: 'string',
                                        title: "Company Address",
                                    },
                                    companyPhoneNumber: { // company
                                        type: 'string',
                                        title: "Company PhoneNumber",
                                    },
                                },
                                "required": ["certificate"]
                            }
                        ]
                    }
                }
            },

        },
        "required": ["firstName", "fullLegalName",]
    };

    const classes = [
        'col-span-4', 'col-span-4', 'col-span-4',
        'col-span-12',
        'col-span-12', 'col-span-12',
        'col-span-12',
    ];

    const ObjectFieldTemplate = (props: ObjectFieldTemplateProps) => {
        return (
            <div className='grid grid-cols-12 gap-4'>
                {props.properties.map((element, index) => (
                    <div key={index} className={classes[index]}>{element.content}</div>
                ))}
            </div>
        );
    }

    const classesPilot = [
        'col-span-12',
        'col-span-6', 'col-span-6',
        'col-span-4', 'col-span-4', 'col-span-4',
        'col-span-6', 'col-span-6'
    ];

    const ObjectFieldTemplatePilot = (props: ObjectFieldTemplateProps) => {
        return (
            <div className='grid grid-cols-12 gap-4'>
                {props.properties.map((element, index) => (
                    <div key={index} className={classesPilot[index]}>{element.content}</div>
                ))}
            </div>
        );
    }

    const classesMaintainer = [
        'col-span-12',
        'col-span-3', 'col-span-3', 'col-span-3', 
        'col-span-12',
        'col-span-6', 'col-span-3', 'col-span-3',
    ];

    const ObjectFieldTemplateMaintainer = (props: ObjectFieldTemplateProps) => {
        return (
            <div className='grid grid-cols-12 gap-4'>
                {props.properties.map((element, index) => (
                    <div key={index} className={classesMaintainer[index]}>{element.content}</div>
                ))}
            </div>
        );
    }

    const uiSchema: UiSchema = {
        'ui:ObjectFieldTemplate': ObjectFieldTemplate,
        pilotInfo: {
            'ui:ObjectFieldTemplate': ObjectFieldTemplatePilot,
        },
        maintainerInfo: {
            'ui:ObjectFieldTemplate': ObjectFieldTemplateMaintainer,
            companyLogo: {
                "ui:widget": "fileUpload"
            }
        },
        image: {
            "ui:widget": "fileUpload"
        }
    };

    const widgets = {
        fileUpload: FileUploadWidget
    };

    return (<>
        <div className="flex justify-center mt-[15px]">
            <Card className='signup-card w-[800px]'>
                <h1 className="text-3xl mb-5">Your details</h1>
                <Form schema={schema} validator={validator} uiSchema={uiSchema} widgets={widgets}
                    onSubmit={async (data) => {
                        showOverlay()
                        const isPilot = data.formData?.pilotInfo?.isPilot;
                        const isMaintainer = data.formData?.maintainerInfo?.isMaintainer;
                        const type = isPilot && isMaintainer ? "PilotAndMaintainer" : isPilot ? "Pilot" : isMaintainer ? "Maintainer" : "None";
                        if (type === "None") {
                            hideOverlay()
                            alert("Please select either pilot or Maintainer")
                        } else {
                            const user = { ...data.formData, type: type, email: localStorage.getItem(userKey) }
                            // console.log(user)
                            await setDoc(doc(db, UsersCollection, localStorage.getItem(userKey)!), user)
                            hideOverlay()
                            navigate('/')
                        }
                    }} >
                    <div className='mt-3'>
                        <CustomButtonFill text='Submit' onClick={() => { }} />
                    </div>
                </Form>
            </Card>
        </div>
    </>)
};