import { Card, Chip, MenuItem, Modal, Select, Tooltip } from "@mui/material"
import { collection, doc, getDocs, getFirestore, query, setDoc, where, writeBatch } from "firebase/firestore"
import { AircraftMxLogAttachmentsCollection, MxLogbookCollection } from "../../../keys"
import { ReactNode, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { useLoadingOverlay } from "../../../loading"
import { MaterialReactTable, MRT_ColumnDef, useMaterialReactTable } from "material-react-table";
import { CustomBack } from "../../../components/backbutton";
import { AircraftModel } from "../../../models/aircraft";
import { useUserAircraftPermissions } from "../../../hooks/usePermissions";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useAircraftById } from "../../../hooks/useAircrafts";
import { MxLogPdfGeneratorModal } from "./mxLogPdfGenerator";
import { MxLogModel } from "../../../models/mx"
import { FirebaseFileDownloader } from "../../../components/fileDownload"
import { ExternalLink } from "../../../components/externalLink"
import { CustomButton, CustomButtonSmall } from "../../../components/button"
import PublishIcon from '@mui/icons-material/Publish';
import CloseIcon from '@mui/icons-material/Close';
import { ExcelUploader } from "../../../components/excelFileSelect"
import { CustomButtonFill } from "../../../components/buttonfill"
import { MultiFileUploadWidget } from "../../../components/multiFileUpload"
import { AircraftMxLogAttachmentsModel } from "../../../models/logAttachments"
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useAllAircraftMXAttachments } from "../../../hooks/useAttachments"
import GppGoodIcon from '@mui/icons-material/GppGood';

export const MxLogbooks = ({ backTo = '/' }: { backTo?: string }) => {
    const [logs, setLogs] = useState<MxLogModel[]>([]);
    const { aircraftId } = useParams();
    const [showImportMx, setShowImportMx] = useState(false)
    const [showAttachments, setShowAttachments] = useState(false)

    const { showOverlay, hideOverlay } = useLoadingOverlay();
    const { data: aircraft } = useAircraftById(aircraftId!)
    const { permissionPower } = useUserAircraftPermissions(aircraft?.id, aircraft?.addedUser!);

    useEffect(() => {
        getAllLogs()
    }, [])

    const getAllLogs = async () => {
        showOverlay()
        const db = getFirestore()

        const data: any[] = [];

        const q = query(collection(db, MxLogbookCollection), where("aircraftId", "==", aircraftId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            data.push({ ...doc.data(), id: doc.id });
        });

        const sortedData = data.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

        setLogs(sortedData);
        hideOverlay()
    }

    return (<>
        <div>
            <Card className="mt-3 pt-3">
                <CustomBack backTo={backTo} />
                <LogbookFilterTable logs={logs} permissionPower={permissionPower} aircraft={aircraft!} >
                    <span className="font-bold ml-3 text-[18px] mt-3 absolute top-0 z-[10] flex flex-row gap-3">{aircraft?.aircraftId} MX logbook
                        {permissionPower >= 4 &&
                            <Tooltip title="Bulk/Batch Import your MX records from an approved XLS template">
                                <div className="">
                                    <CustomButtonSmall text="MX Logbook Importer" onClick={() => { setShowImportMx(true) }} ><PublishIcon /></CustomButtonSmall>
                                </div>
                            </Tooltip>}
                        {permissionPower >= 4 &&
                            <Tooltip title="Upload and organize historical logbooks, manuals, or top-level documents for the Aircraft’s logbooks">
                                <div>
                                    <CustomButtonSmall text="Aircraft Records Vault" onClick={() => { setShowAttachments(true) }} ><GppGoodIcon /></CustomButtonSmall>
                                </div>
                            </Tooltip>
                        }
                    </span>
                </LogbookFilterTable>
            </Card>

            {
                showImportMx && <ImportMxLogs aircraftId={aircraft?.id!} onClose={() => {
                    setShowImportMx(false)
                    getAllLogs()
                }} />
            }
            {
                showAttachments && <MxAttachments aircraftId={aircraft?.id!} onClose={() => {
                    setShowAttachments(false)
                }} />
            }
        </div>
    </>)
}

const styleProps = {
    muiTableHeadCellProps: {
        sx: {
            backgroundColor: '#3A8CC9',
            color: 'white',
        },
    },
}
const LogbookFilterTable = ({ logs, permissionPower, aircraft, children }: {
    aircraft: AircraftModel,
    permissionPower: number,
    logs: any[],
    children: ReactNode
}) => {

    const [showDownloadAsPdf, setshowDownloadAsPdf] = useState(false)
    const [selectedLog, setSelectedLog] = useState<any>()

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: 'date',
                header: 'Date',
                maxSize: 0.7,
                ...styleProps
            },
            {
                id: "Actions",
                header: "Actions",
                maxSize: 0.5,
                Cell: ({ row }) => (
                    <p className="text-gray-700 text-[5px]">
                        {permissionPower >= 4 && <PictureAsPdfIcon sx={{ width: "20px" }} className="ml-1 mr-2 p-0 cursor-pointer" onClick={() => {
                            setSelectedLog(row.original)
                            setshowDownloadAsPdf(true)
                        }} />}
                        {row.original?.imported && <Chip
                            size="small"
                            label="Imported"
                        />}
                    </p>

                ),
                ...styleProps
            },
            {
                accessorKey: 'tachtime',
                header: 'Tach Time',
                Cell: ({ row }) => (
                    <>
                        {row.original.tachtime?.toFixed(1)}
                    </>
                ),
                maxSize: 0.5,
                ...styleProps
            }, {
                accessorKey: 'hobbs',
                header: 'Hobbs',
                maxSize: 0.5,
                Cell: ({ row }) => (
                    <>
                        {row.original.hobbs?.toFixed(1)}
                    </>
                ),
                ...styleProps
            }, {
                accessorKey: 'logbookCategory',
                header: 'Logbook Category',
                Cell: ({ row }) => (
                    <>
                        {row.original.logbookCategory?.join(", ")}
                    </>
                ),
                ...styleProps
            },
            {
                accessorKey: 'tt',
                header: 'Total Time',
                Cell: ({ row }) => (
                    <>
                        {row.original.tt?.toFixed(1)}
                    </>
                ),
                ...styleProps
            },
            {
                accessorKey: 'tsmoh',
                header: 'TSMOH',
                Cell: ({ row }) => (
                    <>
                        {row.original.tsmoh?.toFixed(1)} {row.original.tsmoh_engine2 ? ` / ${row.original.tsmoh_engine2?.toFixed(1)}(E2)` : ""}
                    </>
                ),
                ...styleProps
            },
            {
                accessorKey: 'description',
                header: 'Description',
                size: 1000,
                minSize: 500,
                maxSize: 1000,
                ...styleProps
            },
            // {
            //     accessorKey: 'description',
            //     header: 'Description',
            //     // size: 1000,
            //     // minSize: 500,
            //     // maxSize: 1000,
            //     ...styleProps,
            //     Cell: ({ renderedCellValue, row }) => (
            //         <div className="flex">
            //             <div
            //                 className="truncate max-w-[500px] "
            //             >
            //                 {row.original?.description}
            //             </div>
            //             {row.original?.description && row.original?.description.length > 75 &&
            //                 <InfoOutlined fontSize="small" className="ml-2 cursor-pointer" onClick={() => {
            //                     setSelectedDescription(row.original?.description)
            //                     setShowDescription(true)
            //                 }} />}
            //         </div>
            //     )
            // },
            {
                accessorKey: 'additionalLinks',
                header: 'Additional Links',
                Cell: ({ row }) => (
                    <>
                        {row.original?.additionalLinks?.map((l: any, index: number) => (
                            <ExternalLink key={index} title={l.title} url={l.url} />
                        ))}
                    </>
                ),
                ...styleProps
            },
            {
                accessorKey: 'attachments',
                header: 'Attachments',
                Cell: ({ row }) => (
                    <>
                        {row.original?.attachments?.map((l: any, index: number) => (
                            <FirebaseFileDownloader filePath={l} key={index} />
                        ))}
                    </>
                ),
                ...styleProps
            },
            {
                accessorKey: 'maintainerFullLegalName',
                header: 'Maintainer Full Legal Name',
                ...styleProps
            }, {
                accessorKey: 'maintainerFirstName',
                header: 'Maintainer First Name',
                ...styleProps
            }, {
                accessorKey: 'mi',
                header: 'MI',
                ...styleProps
            }, {
                accessorKey: 'maintainerLastName',
                header: 'Maintainer Last Name',
                ...styleProps
            }, {
                accessorKey: 'certificate',
                header: 'Certificate',
                ...styleProps
            }, {
                accessorKey: 'ia',
                header: 'IA',
                Cell: ({ row }) => (
                    <>
                        {row.original.ia ? "Yes" : "No"}
                    </>
                ),
                filterFns: {
                    equals: (row: any, columnId: any, filterValue: any) => {
                        const value = row.getValue(columnId);
                        // Handle undefined or null values
                        if (value === undefined || value === null) {
                            return filterValue === "" || filterValue === undefined;
                        }
                        return filterValue ? value.toString() === filterValue : true;
                    },
                },
                Filter: ({ column }) => {
                    const { getFilterValue, setFilterValue } = column;
                    const filterValue = getFilterValue();

                    return (
                        <Select
                            value={filterValue || ""}
                            onChange={(e) => setFilterValue(e.target.value || undefined)} // undefined removes the filter
                            displayEmpty
                            fullWidth
                            style={{ height: "30px" }}
                        >
                            <MenuItem key={""} value="">All</MenuItem>
                            <MenuItem key={"yes"} value="true">Yes</MenuItem>
                            <MenuItem key={"no"} value="false">No</MenuItem>
                        </Select>
                    );
                },
                ...styleProps
            }, {
                accessorKey: 'aAndP',
                header: 'A&P',
                Cell: ({ row }) => (
                    <>
                        {row.original.aAndP ? "Yes" : "No"}
                    </>
                ),
                filterFns: {
                    equals: (row: any, columnId: any, filterValue: any) => {
                        const value = row.getValue(columnId);
                        // Handle undefined or null values
                        if (value === undefined || value === null) {
                            return filterValue === "" || filterValue === undefined;
                        }
                        return filterValue ? value.toString() === filterValue : true;
                    },
                },
                Filter: ({ column }) => {
                    const { getFilterValue, setFilterValue } = column;
                    const filterValue = getFilterValue();

                    return (
                        <Select
                            value={filterValue || ""}
                            onChange={(e) => setFilterValue(e.target.value || undefined)} // undefined removes the filter
                            displayEmpty
                            fullWidth
                            style={{ height: "30px" }}
                        >
                            <MenuItem key={""} value="">All</MenuItem>
                            <MenuItem key={"yes"} value="true">Yes</MenuItem>
                            <MenuItem key={"no"} value="false">No</MenuItem>
                        </Select>
                    );
                },
                ...styleProps
            }, {
                accessorKey: 'owner',
                header: 'Owner',
                Cell: ({ row }) => (
                    <>
                        {row.original.owner ? "Yes" : "No"}
                    </>
                ),
                filterFns: {
                    equals: (row: any, columnId: any, filterValue: any) => {
                        const value = row.getValue(columnId);
                        // Handle undefined or null values
                        if (value === undefined || value === null) {
                            return filterValue === "" || filterValue === undefined;
                        }
                        return filterValue ? value.toString() === filterValue : true;
                    },
                },
                Filter: ({ column }) => {
                    const { getFilterValue, setFilterValue } = column;
                    const filterValue = getFilterValue();

                    return (
                        <Select
                            value={filterValue || ""}
                            onChange={(e) => setFilterValue(e.target.value || undefined)} // undefined removes the filter
                            displayEmpty
                            fullWidth
                            style={{ height: "30px" }}
                        >
                            <MenuItem key={""} value="">All</MenuItem>
                            <MenuItem key={"yes"} value="true">Yes</MenuItem>
                            <MenuItem key={"no"} value="false">No</MenuItem>
                        </Select>
                    );
                },
                ...styleProps
            },
        ],
        [permissionPower],
    );

    const table = useMaterialReactTable({
        columns,
        data: logs,
        initialState: { showColumnFilters: true, density: "compact", },
    });

    return <div className="relative">
        <MaterialReactTable table={table} />
        {children}

        {
            showDownloadAsPdf && <MxLogPdfGeneratorModal aircraft={aircraft} formValues={selectedLog}
                tt={(selectedLog.tt)?.toFixed(1)}
                tsmoh={(selectedLog.tsmoh)?.toFixed(1)}
                tsmoh_engine2={aircraft?.engineMoake_engine2 ? (selectedLog.tsmoh_engine2)?.toFixed(1) : 0}
                mxId={selectedLog.id}
                onClose={() => setshowDownloadAsPdf(false)} />
        }

    </div>;
};


export const ImportMxLogs = ({ aircraftId, onClose }: { aircraftId: string, onClose: () => void }) => {
    const { showOverlay, hideOverlay } = useLoadingOverlay();
    const [jsonData, setJsonData] = useState([]);

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 3,
    };

    const UploadMxLogs = async () => {
        if (jsonData?.length === 0) return;

        showOverlay()

        try {

            const db = getFirestore()
            const batch = writeBatch(db); // Create a batch
            const collectionRef = collection(db, MxLogbookCollection);

            // [
            //     {
            //         "date": "2000-11-11",
            //         "tach": 100,
            //         "hobbs": "",
            //         "tt": 1,
            //         "tsmoh": 1,
            //         "logbook": "ENGINE",
            //         "description_of_inspection,_test,_repair,_and_or_alteration": "testtttt",
            //         "company_name,_address,_phone": "testjdhgfjfgsj",
            //         "first_name": "sdfsdf",
            //         "mi": "sdf",
            //         "last_name": "sdfsdfsd",
            //         "certificate_#": 324234,
            //         "ia": "YES",
            //         "a&p": "NO",
            //         "owner": "NO"
            //     }
            // ]

            jsonData.forEach((row: any) => {
                let mxLogToSave = {
                    imported: true,
                    date: row.date ?? "",
                    ia: !!row.ia && (row.ia === "YES"),
                    aAndP: !!row["a&p"] && (row["a&p"] === "YES"),
                    owner: !!row.owner && (row.owner === "YES"),
                    tachtime: row.tach !== "" ? row.tach : 0,
                    tachtime2: 0,
                    hobbs: row.hobbs !== "" ? row.hobbs : 0,
                    hobbs2: 0,
                    logbookCategory: [row.logbook],
                    description: row["description_of_inspection,_test,_repair,_and_or_alteration"] ?? "",
                    maintainerFullLegalName: (row.first_name ?? "") + " " + (row.last_name ?? ""),
                    maintainerFirstName: row.first_name ?? "",
                    mi: row.mi ?? "",
                    maintainerLastName: row.last_name ?? "",
                    // maintainerId: "",
                    certificate: row["certificate_#"] ?? "",
                    // oilChange: false,
                    // oilChange_engine2: false,
                    // engine_annual: false,
                    // engine2_annual: false,
                    // airframe_annual: false,
                    // signature: null,
                    tt: row.tt !== "" ? row.tt : 0,
                    tsmoh: row.tsmoh !== "" ? row.tsmoh : 0,
                    tsmoh_engine2: 0,
                    aircraftId: aircraftId,
                };

                // console.log(mxLogToSave)

                const docRef = doc(collectionRef); // Generate a new document reference
                batch.set(docRef, mxLogToSave); // Add document to the batch
            });

            await batch.commit();
            onClose();
        } catch (error) {
            console.error("Error saving data in bulk:", error);
        } finally {
            hideOverlay()
        }
    }

    return <>
        <Modal
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Card className='signup-card w-[1000px] min-h-[300px]' sx={style}>
                <div className='flex  flex-row justify-between'>
                    <h1 className="text-3xl mb-5">Import Multiple Logbook Entries into AirLogbooks</h1>
                    <CloseIcon className='hover:cursor-pointer' onClick={() => onClose()}></CloseIcon>
                </div>
                <div className="overflow-scroll max-h-[70vh]">
                    <div className="">
                        AirLogbooks allows you to bulk import historical maintenance (MX) records using an approved XLS template. Imported records will be marked as 'Imported' for easy identification.
                        <br /><br />CAUTION: This tool does not check for duplicates. To avoid duplication, ensure only new records are included in your batch import.
                    </div>
                    <div className="pt-5">
                        <b>Please download this template for import logs.</b> <div className="w-[35%]"><FirebaseFileDownloader filePath={"templates/Aircraft Logbooks Template.xlsx"} /></div>
                    </div>
                    <div className="pt-5">
                        <ExcelUploader label="Select Mx file to import" onExtractData={async (jsonData: any) => {
                            setJsonData(jsonData);
                            console.log(jsonData)
                        }} />
                    </div>
                    {jsonData.length > 0 && <pre className="text-[13px]">
                        The File contain {jsonData.length} logs.
                    </pre>}
                </div>

                <div className="mt-5 ml-auto">
                    <CustomButtonFill text='Save Mx logs' onClick={() => { UploadMxLogs() }} />
                </div>
            </Card>
        </Modal>
    </>
}

export const MxAttachments = ({ aircraftId, onClose }: { aircraftId: string, onClose: () => void }) => {
    const { showOverlay, hideOverlay } = useLoadingOverlay();
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 3,
    };

    const { data: mxAttachmentDoc, refreshAllAircraftMXAttachments } = useAllAircraftMXAttachments(aircraftId);

    const handleMultifileUpload = async (e: any) => {
        const db = getFirestore();
        const { name, value } = e;

        var attachments: AircraftMxLogAttachmentsModel = {
            aircraftId: aircraftId,
            attachments: [...mxAttachmentDoc?.attachments ?? [], ...value]
        }

        await setDoc(doc(db, AircraftMxLogAttachmentsCollection, aircraftId), attachments)
        refreshAllAircraftMXAttachments();
    }

    return <>
        <Modal
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Card className='signup-card w-[1000px] min-h-[300px]' sx={style}>
                <div className='flex  flex-row justify-between'>
                    <h1 className="text-3xl mb-5">AC Records Vault</h1>
                    <CloseIcon className='hover:cursor-pointer' onClick={() => onClose()}></CloseIcon>
                </div>
                <div className="overflow-scroll max-h-[70vh]">
                    <div className="mb-3">
                        {mxAttachmentDoc?.attachments?.map((l: any, index: number) => (
                            <FirebaseFileDownloader filePath={l} key={index} showDelete={true}
                                onDelete={async (filePath: string) => {
                                    showOverlay()
                                    const db = getFirestore();

                                    var attachments: AircraftMxLogAttachmentsModel = {
                                        aircraftId: aircraftId,
                                        attachments: [...mxAttachmentDoc?.attachments.filter(f => f !== filePath) ?? []]
                                    }

                                    await setDoc(doc(db, AircraftMxLogAttachmentsCollection, aircraftId), attachments)
                                    refreshAllAircraftMXAttachments();
                                    hideOverlay()
                                }} />
                        ))}
                    </div>
                    <div className="mb-3">
                        Safely Store your aircraft’s digital logbook records in the AirLogbooks cloud.
                    </div>

                    <MultiFileUploadWidget
                        id="mx-log files"
                        label={"Upload Mx Log files"}
                        folderName={`MxDocuments`}
                        // value={formValues.image}
                        onChange={(e: string[]) => handleMultifileUpload({ name: "attachments", value: e })}
                    />
                </div>

                <div className="mt-5 ml-auto">
                    <CustomButton text='OK' onClick={() => { onClose() }} />
                </div>
            </Card>
        </Modal>
    </>
}

{/* <div className="font-[700] text-[22px] text-[#85144B] p-3"></div>
                <table className="pt-5 w-[100%] border-2 border-[#CCCCCC]">
                    <tbody>
                        <tr className="bg-[#3A8CC9] p-3 w-[100%] h-[57px]">
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Date</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Tach Time</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Hobbs</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Logbook Category</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Description</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Maintainer Full Legal Name</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Maintainer First Name</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">MI</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Maintainer Last Name</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Certificate</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">IA</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">A&P</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Owner</th>
                        </tr>
                        {
                            logs.map((log, index) => <tr className="h-[57px] text-center  border">
                                <td className="border text-[8px]">{log?.date}</td>
                                <td className="border text-[8px]">{log?.tachtime?.toFixed(1)}</td>
                                <td className="border text-[8px]">{log?.hobbs?.toFixed(1)}</td>
                                <td className="border text-[8px]">{log?.logbookCategory}</td>
                                <td className="border text-[8px]">{log?.description}</td>
                                <td className="border text-[8px]">{log?.maintainerFullLegalName}</td>
                                <td className="border text-[8px]">{log?.maintainerFirstName}</td>
                                <td className="border text-[8px]">{log?.mi}</td>
                                <td className="border text-[8px]">{log?.maintainerLastName}</td>
                                <td className="border text-[8px]">{log?.certificate}</td>
                                <td className="border text-[8px]">{log?.ia ? 'YES' : 'NO'}</td>
                                <td className="border text-[8px]">{log?.aAndP ? 'YES' : 'NO'}</td>
                                <td className="border text-[8px]">{log?.owner ? 'YES' : 'NO'}</td>
                            </tr>)
                        }
                    </tbody>

                </table> */}