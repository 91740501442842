import { getStorage, ref, getDownloadURL } from "firebase/storage";
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { Card, Modal, Tooltip } from "@mui/material";
import { useLoadingOverlay } from "../loading";
import CloseIcon from '@mui/icons-material/Close';
import { CustomButtonFill } from "./buttonfill";
import { CustomButton } from "./button";
import { useState } from "react";

export const FirebaseFileDownloader: React.FC<{ filePath: string, showDelete?: boolean, onDelete?: (filePath: string) => void }> = ({ filePath, showDelete, onDelete }) => {
    const storage = getStorage();
    const [shoeDeleteModal, setShowDeleteModal] = useState(false);
    

    const openFileInNewWindow = async () => {
        try {
            const fileRef = ref(storage, filePath);
            const url = await getDownloadURL(fileRef);

            // Open the URL in a new browser tab
            window.open(url, "_blank");
        } catch (error) {
            console.error("Error opening file:", error);
        }
    };

    return (
        <div className="flex items-center border-[1px] rounded-[5px] p-1 mt-1 border-[#4995CF] cursor-pointer">
            <span onClick={openFileInNewWindow}>{filePath.split("/").pop()?.split("___").pop()}</span>
            <Tooltip title="Download">
                <DownloadIcon target="_blank" fontSize={"inherit"} className="text-blue-500 hover:text-blue-700 ml-3" onClick={openFileInNewWindow} />
            </Tooltip>
            {showDelete && <Tooltip title="Delete">
                <DeleteIcon fontSize={"inherit"} className="text-blue-500 hover:text-blue-700 ml-auto" onClick={() => { setShowDeleteModal(true) }} />
            </Tooltip>}

            {shoeDeleteModal && <DeleteFile onDelete={() => {
                if (onDelete)
                    onDelete(filePath)
            }} onClose={() => { setShowDeleteModal(false) }} />}
        </div>
    );
};

const DeleteFile = ({ onDelete, onClose }: { onDelete: () => void, onClose: () => void }) => {
    const { showOverlay, hideOverlay } = useLoadingOverlay();
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 3,
    };

    return <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Card className='' sx={style}>
            <div className='flex  flex-row justify-between'>
                <h1 className="text-3xl mb-5">Delete AC Record</h1>
                <CloseIcon className='hover:cursor-pointer' onClick={() => onClose()}></CloseIcon>
            </div>
            <h3>
                This can not be undone. You will lose all data! Are you sure?
            </h3>

            <div className="flex mt-5 gap-3">
                <CustomButtonFill text='Yes' onClick={async () => {
                    onDelete();
                    onClose()
                }} />
                <CustomButton text="No" onClick={() => onClose()} />
            </div>

        </Card>
    </Modal>
}

// export const FirebaseFileDownloader: React.FC<{ filePath: string; }> = ({ filePath }) => {
//     const storage = getStorage();

//     const downloadFile = async () => {
//         try {
//             const fileRef = ref(storage, filePath);
//             const url = await getDownloadURL(fileRef);

//             // Create a temporary link element to trigger download
//             const a = document.createElement("a");
//             a.href = url;
//             a.download = filePath.split("/").pop() || "download";
//             document.body.appendChild(a);
//             a.click();
//             document.body.removeChild(a);
//         } catch (error) {
//             console.error("Error downloading file:", error);
//         }
//     };

//     return (
//         <div className="">
//             {filePath.split("/").pop()} <DownloadIcon target="_blank" fontSize={"small"} className="text-blue-500 hover:text-blue-700 cursor-pointer" onClick={downloadFile}/>
//         </div>
//     );
// };