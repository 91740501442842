import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import { SquawkCollection } from "../keys";

export type PriorityType = "High" | "Medium" | "Low";
const priority = { "High": 1, "Medium": 2, "Low": 3 };

export const getAllSquawks_firebase = async (aircraftId: string) => {
    const db = getFirestore()
    const data: any[] = [];

    const q = query(collection(db, SquawkCollection), where("status", "==", "Pending"), where("aircraftId", "==", aircraftId));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
    });
    data.sort((a: any, b: any) => priority[a.severity as PriorityType] - priority[b.severity as PriorityType]);

    return data;
}