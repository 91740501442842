import { useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeyAircraftSquawks } from "../keys";
import { getAllSquawks_firebase } from "../services/squawks";

export const useAircraftSquawks = (aircraftId: string) => {
    const queryClient = useQueryClient()

    const query = useQuery<any[]>({
        queryKey: [queryKeyAircraftSquawks, aircraftId],
        queryFn: () => getAllSquawks_firebase(aircraftId)
    })

    const invalidateAircraftSquawks = () => {
        queryClient.invalidateQueries({ queryKey: [queryKeyAircraftSquawks, aircraftId] });
    };

    return {
        ...query,
        refreshAircraftSquawks: invalidateAircraftSquawks,
    };
}