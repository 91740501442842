import { useQuery, useQueryClient } from "@tanstack/react-query";
import { UserModel } from "../models/user";
import { getUser_firebase } from "../services/user";
import { queryKeyUser, userKey } from "../keys";

export const useUser = () => {
    const queryClient = useQueryClient()
    const userId = localStorage.getItem(userKey)!

    const query = useQuery<UserModel | null>({ queryKey: [queryKeyUser, userId], queryFn: () => getUser_firebase(userId) })

    const invalidateUser = () => {
        queryClient.invalidateQueries({ queryKey: [queryKeyUser, userId] });
    };

    return {
        ...query,
        refreshUser: invalidateUser,
    };
};


export const useUserById = (userId: string) => {
    const queryClient = useQueryClient()

    const query = useQuery<UserModel | null>({
        queryKey: [queryKeyUser, userId], queryFn: () => {
            if (userId && (localStorage.getItem(userKey) == userId))
                return getUser_firebase(userId)

            return null
        }
    })

    const invalidateUser = () => {
        queryClient.invalidateQueries({ queryKey: [queryKeyUser, userId] });
    };

    return {
        ...query,
        refreshUser: invalidateUser,
    };
};