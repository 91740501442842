import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

export const CustomBack = ({ backTo = '/', text = 'Return To Dashboard' }: { backTo?: string, text?: string }) => {
    const navigate = useNavigate();

    // return <div className="border border-[#4995CF] text-[#4995CF] border-[1px] rounded-full h-[36px] px-3 align-center ml-auto cursor-pointer"
    //     onClick={() => navigate('/')}><ArrowBackIcon /> Dashboard</div>

    return <h5 className='align-middle text-[#4995CF] font-bold ml-3 cursor-pointer' onClick={() => navigate(backTo)}> <ArrowBackIcon className='mt-[-2px]' />{text} </h5>
}