import { doc, getFirestore, setDoc } from "firebase/firestore";
import { useState } from "react";
import { useLoadingOverlay } from "../../../loading";
import { AircraftADCompliancesCollection } from "../../../keys";
import { Box, Card, Grid, Modal, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { CustomButtonFill } from "../../../components/buttonfill";

export const AddAdReminder = ({ ad, onClose }: { ad: any, onClose: () => void }) => {
    const [formValues, setFormValues] = useState({
        "days": null,
        "hours": null,
    });
    const db = getFirestore()
    const { showOverlay, hideOverlay } = useLoadingOverlay();

    const [formErrors, setFormErrors] = useState({
        "days": false,
        "hours": false
    });

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 3,
    };

    const handleChange = (e: any) => {

        const { name, value } = e.target;

        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleChangeCheckbox = (e: any) => {

        const { name, checked } = e.target;
        setFormValues({
            ...formValues,
            [name]: checked,
        });
    };

    const handleChangeNumber = (e: any) => {

        const { name, value } = e.target;

        setFormValues({
            ...formValues,
            [name]: parseFloat(value),
        });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        showOverlay();

        // let adDoc = {
        //     ...ad,
        //     ...formValues
        // }
        // await setDoc(doc(db, AircraftADCompliancesCollection, ad.id), adDoc)
        // hideOverlay()

        onClose();
    };

    return (<Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Card className='signup-card w-[700px]' sx={style}>
            <div className='flex  flex-row justify-between'>
                <h1 className="text-3xl mb-5">Notify me!</h1>
                <CloseIcon className='hover:cursor-pointer' onClick={() => onClose()}></CloseIcon>
            </div>

            <Box
                component="form"
                sx={{ flexGrow: 1 }}
                onSubmit={handleSubmit}
                className="mt-4"
            >
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="In Days"
                            name="days"
                            value={formValues.days}
                            onChange={handleChange}
                            error={formErrors.days}
                            className="w-full"
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="In Hours"
                            name="hours"
                            value={formValues.hours}
                            onChange={handleChange}
                            error={formErrors.hours}
                            className="w-full"
                            required
                        />
                    </Grid>

                </Grid>

                <div className="mt-5">
                    <CustomButtonFill text='Submit' onClick={() => { }} />
                </div>
            </Box>
        </Card>
    </Modal>)
}